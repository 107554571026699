import { Children, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectComponent, { MenuPlacement, components } from 'react-select';
import { FieldProps, FormikProps, FormikValues, useFormikContext } from 'formik';
import cn from 'classnames';

import { useOutsideClickChecker } from '../../utils/hooks';
import { FormFieldSize } from '../../types';
import { FormError } from './FormError';
import Badge from './Badge';
import { getDataTest } from '../../utils';

export interface OptionProps {
    label: string;
    value: string;
}

interface SelectProps {
    form?: FormikProps<FormikValues>;
    label: string | null;
    field?: FieldProps['field'];
    options: Array<OptionProps>;
    isCenter?: boolean;
    defaultValue?: { value: string; label: string; disabled?: boolean };
    isSearchable?: boolean;
    isClearable?: boolean;
    isMulti?: boolean;
    className?: string;
    isDisabled?: boolean;
    placeholder?: string;
    size?: FormFieldSize;
    closeMenuOnSelect?: boolean;
    handleChange?: (value: any) => void;
}

export const Select = ({
    field,
    form,
    defaultValue,
    handleChange,
    label,
    isDisabled,
    isSearchable,
    isCenter,
    isClearable,
    isMulti = false,
    options,
    placeholder,
    className,
    size = 'medium',
    closeMenuOnSelect = true,
    ...props
}: SelectProps) => {
    const { t } = useTranslation();
    const { setFieldValue } = useFormikContext();
    const vcChildRef = useRef<any>();
    const menuRef = useRef<HTMLDivElement | null>(null);
    const [menuPlacement, setMenuPlacement] = useState<MenuPlacement | undefined>('auto');
    const [menuHeight, setMenuHeight] = useState(300);

    const Control = ({ children, ...controlProps }: any) => (
        <components.Control {...controlProps} className={cn(size === 'small' ? 'h-10' : 'h-[3rem] md:h-[3.25rem]')}>
            <div className="relative flex w-full">{children}</div>
        </components.Control>
    );

    const IndicatorsContainer = ({ children, ...continerProps }: any) => (
        <components.IndicatorsContainer {...continerProps}>
            <div className="flex flex-row" data-test={getDataTest(props, 'Indicators')}>
                {children}
            </div>
        </components.IndicatorsContainer>
    );

    const DropdownIndicator = ({ children, ...dropdownProps }: any) =>
        isDisabled ? null : (
            <components.DropdownIndicator
                {...dropdownProps}
                className={cn(
                    'flex h-6 w-6 rotate-0 items-center justify-center rounded-full bg-purple !p-1 pl-4 !text-white transition-all',
                    dropdownProps.selectProps.menuIsOpen && '!rotate-180',
                    isDisabled && '!bg-gray',
                    size === 'small' ? 'mr-1.5' : 'mr-3'
                )}
            >
                {children}
            </components.DropdownIndicator>
        );

    const MenuList = ({ children, ...menuProps }: any) => (
        <components.MenuList {...menuProps} className="scrollbar z-10 my-3" maxHeight={menuHeight}>
            <div data-test={getDataTest(props, 'MenuList')}>{children}</div>
        </components.MenuList>
    );

    const Option = ({ children, ...optionProps }: any) => (
        <components.Option {...optionProps}>
            <div data-test={getDataTest(props, 'Option')}>{children}</div>
        </components.Option>
    );

    const CheckBoxOption = ({ Icon, children, isSelected, ...rest }: any) => {
        return (
            <components.Option {...rest}>
                <div
                    data-test={getDataTest(props, 'Option')}
                    className="text-nowrap w-90 overflow-hidden text-ellipsis whitespace-nowrap"
                >
                    <input type="checkbox" defaultChecked={isSelected} className="mr-2" />
                    {children}
                </div>
            </components.Option>
        );
    };

    const SelectContainer = ({ children, ...containerProps }: any) => (
        <components.SelectContainer {...containerProps}>
            <div data-test={getDataTest(props, 'Input')}>{children}</div>
        </components.SelectContainer>
    );

    const ValueContainer = ({ children, getValue, ...rest }: any) => {
        if (!isMulti) {
            return <components.ValueContainer {...rest}>{children}</components.ValueContainer>;
        }

        var length = getValue().length;
        let displayChips =
            length > 1 ? (
                <span>
                    {t('common.selectedOptionsMessage')} <Badge value={length} />
                </span>
            ) : (
                Children.toArray(children)[0]
            );

        return (
            <components.ValueContainer {...rest} style={{ padding: 0 }}>
                <div
                    className="text-nowrap w-90 w-full overflow-hidden text-ellipsis whitespace-nowrap"
                    onClick={() => {
                        vcChildRef.current.onInputFocus();
                    }}
                >
                    {displayChips}
                </div>
            </components.ValueContainer>
        );
    };

    const MultiValueContainer = ({ data }: any) => {
        if (data) {
            return data.label;
        }
        return '';
    };

    const value = defaultValue
        ? defaultValue
        : options
        ? isMulti
            ? options.filter((option) => field?.value?.find((v: OptionProps) => option?.value === v?.value))
            : options.find((option) => option?.value === field?.value?.value)
        : '';

    useOutsideClickChecker(menuRef, () => {
        if (!isSearchable && !closeMenuOnSelect) {
            vcChildRef?.current?.props?.onMenuClose();
        }
    });

    const onScroll = useCallback(() => {
        if (menuRef?.current) {
            if (menuRef?.current?.getBoundingClientRect().top > window.innerHeight / 2) {
                setMenuPlacement('top');
            } else {
                setMenuPlacement('bottom');
            }
            if (window.innerHeight < 800) {
                setMenuHeight(180);
            } else if (menuHeight !== 300) {
                setMenuHeight(300);
            }
        }
    }, [menuRef, menuHeight]);

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, [onScroll]);

    useEffect(() => {
        window.addEventListener('resize', onScroll);
        return () => window.removeEventListener('resize', onScroll);
    }, [onScroll]);

    useEffect(() => {
        onScroll();
    }, [onScroll]);

    return (
        <div className={cn(className, 'w-full')} ref={menuRef}>
            {label && (
                <label
                    className={cn(
                        'mb-2 block cursor-pointer pl-4 text-sm text-darkPurple',
                        isCenter && 'text-center',
                        isDisabled && 'pointer-events-auto cursor-auto text-gray',
                        size === 'small' && 'text-xs'
                    )}
                    data-test={getDataTest(props, 'Label')}
                >
                    {label}
                </label>
            )}

            <SelectComponent
                ref={vcChildRef}
                isClearable={!isDisabled && isClearable}
                classNamePrefix="react-select"
                options={options}
                className={cn(
                    className,
                    isCenter && 'react-select--is-centered',
                    isDisabled && 'react-select--is-disabled',
                    field?.name && form?.errors[field.name] && form?.touched[field.name] && 'react-select--has-error',
                    'react-select-container'
                )}
                name={field?.name}
                id={field?.name}
                onChange={(value: any) => {
                    if (field?.name) {
                        setFieldValue(field?.name, value);
                        if (handleChange) {
                            const currentVal = value as OptionProps;
                            handleChange({ name: field?.name, value: currentVal?.value });
                        }
                    }
                }}
                value={value}
                placeholder={placeholder ?? `${t('common.chooseValue')}...`}
                isMulti={isMulti}
                isSearchable={isSearchable}
                blurInputOnSelect={false}
                closeMenuOnSelect={closeMenuOnSelect}
                menuPlacement={menuPlacement}
                noOptionsMessage={() => t('common.noSelectOptionsMessage')}
                components={{
                    Control,
                    SelectContainer,
                    IndicatorSeparator: () => null,
                    IndicatorsContainer,
                    DropdownIndicator,
                    MenuList,
                    Option: isMulti ? CheckBoxOption : Option,
                    ValueContainer,
                    MultiValueContainer,
                }}
                {...props}
            />

            <FormError
                name={field?.name}
                className={cn('mt-2', isCenter ? 'text-center' : 'pl-4')}
                data-test={getDataTest(props, 'Error')}
            />
        </div>
    );
};
