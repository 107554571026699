import { useContext, useMemo, useRef, useState } from 'react';
import { Form, FormikProps, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Head } from '../../components/Head';
import { CustomFormik } from '../../components/CustomFormik';
import { DEFAULT_PHONEPREFIX_ID, REGEX, ROUTE, SMS_CODE_LENGTH, STEP_CODE } from '../../constants';
import { AppContext } from '../../components/Context';
import { getHigherStep, getOwner, getStepPosition, isDevelopment, prevalidateValues } from '../../utils';
import { ModelProps, ParticipantPersonProps, PersonalIdentificationFullProps } from '../../types/model';
import {
    CallConfirmClientPhoneNumberResponse,
    CallHasClientByContactAnyActivePolicyResponse,
    CallIsContactExistingAgentResponse,
    CallRegisterClientPhoneNumberResponse,
    callConfirmClientPhoneNumber,
    callCreateEmailVerification,
    callGetClientConsent,
    callHasClientByContactAnyActivePolicy,
    callIsContactExistingAgent,
    callRegisterClientPhoneNumber,
} from '../../apis/support';
import { CountryType, ReactSelectItemType } from '../../types';
import Loader from '../../components/common/Loader';
import { ArrangementKind } from '../../types/enums/ArrangementKind';
import { useAppNavigate, useContextHelpers } from '../../utils/hooks';
import Alert from '../../components/Alert';
import LimitedAccess from '../../components/LimitedAccess';
import { Layout } from '../../components/Layout';
import FooterContent from './FooterContent';
import { DetectFormikChanged } from '../../components/common/DetectFormikChanged';
import ContactsForm from './ContactsForm';
import WaitingForClientSMS from './WaitingForClientSMS';
import { ContactContext } from './ContactContext';
import WaitingForClientConsents from './WaitingForClientConsents';

interface ContactDataType {
    email: string;
    phoneNumberPrefix?: ReactSelectItemType;
    phoneNumber: string;
    smsCode: string;
    noMailbox: boolean;
}

enum ContactModes {
    STANDARD = '',
    WAIT_FOR_CLIENT_CONSENTS = 'WAIT_FOR_CLIENT_CONSENTS',
    WAIT_FOR_CLIENT_SMS_CODE = 'WAIT_FOR_CLIENT_SMS_CODE',
}

export const ContactPage = () => {
    const { t } = useTranslation();
    const { initData, ...ctx } = useContext(AppContext);
    const { contactFormSent, setContactFormSent, setResendEnabled, isContactFormSended, sendConsent, setSendConsent } =
        useContext(ContactContext);
    const { maxStepCode } = useContextHelpers();
    const { navigateTo } = useAppNavigate();
    const [errorInValidateContacts, setErrorInValidateContacts] =
        useState<CallHasClientByContactAnyActivePolicyResponse | null>(null);
    const [showErrorConsentsNotExists, setShowErrorConsentsNotExists] = useState(false);
    const [errorInExistingAgent, setErrorInExistingAgent] = useState<CallIsContactExistingAgentResponse | null>(null);
    const [mode, setMode] = useState<string>(ContactModes.STANDARD);
    const myFormikRef = useRef<FormikProps<ContactDataType>>(null);
    const countries: Array<CountryType> = initData?.Lovs?.Countries || [];
    const data = ctx.currentModel;
    const [isValid, setIsValid] = useState(false);
    const [formUnlock, setFormUnlock] = useState(false);
    const [continueDisabled, setContinueDisabled] = useState(false);

    const prefixes = useMemo(() => initData?.InternationalPhonePrefixes || [], [initData]);

    // data pro stránku kontaktu
    const illustrationSettings = data?.Settings?.IllustrationSettings;
    const formSubmitted = illustrationSettings?.ContactFormSubmitted === true;
    const phoneNumberRegistered = illustrationSettings?.ContactPhoneNumberRegistered;
    const phoneNumberRegisterResponse = illustrationSettings?.ContactPhoneNumberRegisterResponse;
    const phoneNumberConfirmed = illustrationSettings?.ContactPhoneNumberConfirmed;
    const emailRegistered = illustrationSettings?.ContactEmailRegistered;

    const token = localStorage.getItem('token') ?? '';

    const isRemote = data?.Settings?.ArrangementType === ArrangementKind.Remote;
    const isF2F = !isRemote;

    const participant = data?.Participants?.[0] as ParticipantPersonProps<PersonalIdentificationFullProps>;

    const formValues = myFormikRef?.current?.values;

    const isContactFormDisabled = useMemo(() => {
        const ret =
            (contactFormSent || mode === ContactModes.WAIT_FOR_CLIENT_CONSENTS || formSubmitted) &&
            formValues?.email !== '' &&
            formValues?.phoneNumber !== '' &&
            isF2F &&
            !formUnlock;

        return ret;
    }, [contactFormSent, mode, formSubmitted, isF2F, formValues, formUnlock]);

    const isContinueEnabled = useMemo(() => {
        return (
            !continueDisabled ||
            !!(
                contactFormSent &&
                maxStepCode &&
                getStepPosition(maxStepCode) >= getStepPosition(STEP_CODE.CONTACTS_CHECK)
            )
        );
    }, [continueDisabled, contactFormSent, maxStepCode]);

    // je zobrazen input pro SMS kód a je povinný
    const isSmsCodeRequired =
        isContactFormSended(myFormikRef?.current?.values) && mode === ContactModes.WAIT_FOR_CLIENT_SMS_CODE;

    const handleChangeContacts = async () => {
        if (formSubmitted) {
            setIsValid(true);
        }
        setFormUnlock(true);
        setContactFormSent(null);
        setContinueDisabled(false);
        setErrorInExistingAgent(null);
        setErrorInValidateContacts(null);
        setMode(ContactModes.STANDARD);
        myFormikRef.current?.setFieldValue('smsCode', '');
    };

    const formattedPrefixes = useMemo<Array<ReactSelectItemType>>(
        () => (prefixes || []).map((p: any) => ({ label: p.PhonePrefix, value: p.CountryId.toString() })),
        [prefixes]
    );

    const defaultPrefix = formattedPrefixes?.find((fp) => fp.value === DEFAULT_PHONEPREFIX_ID);

    const setErrorSmsCode = (error?: any) => {
        if (error?.response?.status === 401) {
            myFormikRef?.current?.setFieldError('smsCode', t<string>('common.formErrors.wrongSmsCode'));
        } else if (error?.response?.status === 410) {
            myFormikRef?.current?.setFieldError('smsCode', t<string>('common.formErrors.smsCodeExpired'));
        } else {
            myFormikRef?.current?.setFieldError('smsCode', t<string>('common.error'));
        }
    };

    const setDisableResend = () => {
        setResendEnabled(false);
        setTimeout(() => {
            setResendEnabled(true);
        }, 2 * 60 * 1000);
    };

    const formSchema = Yup.object().shape({
        email: Yup.string()
            .matches(new RegExp(REGEX.EMAIL_REGEX), t('common.formErrors.wrongEmailFormat') || '')
            .required(),
        phoneNumber: Yup.string()
            .when('phoneNumberPrefix', (x: Array<any>) =>
                x[0]?.value === DEFAULT_PHONEPREFIX_ID
                    ? Yup.string()
                          .matches(new RegExp(REGEX.POLISH_PHONE_REGEX), t('common.formErrors.wrongPhoneFormat') || '')
                          .required()
                    : Yup.string()
                          .matches(new RegExp(REGEX.BASIC_PHONE_REGEX), t('common.formErrors.wrongPhoneFormat') || '')
                          .required()
            )
            .matches(new RegExp(REGEX.BASIC_PHONE_REGEX), t('common.formErrors.wrongPhoneFormat') || '')
            .required(),
        phoneNumberPrefix: Yup.object().required(),
        smsCode: isSmsCodeRequired
            ? Yup.string()
                  .matches(/^[0-9]+$/, t<string>('pages.contactConfirmation.smsCodeOnlyDigits'))
                  .length(
                      SMS_CODE_LENGTH,
                      t<string>('pages.contactConfirmation.smsCodeLength', { length: SMS_CODE_LENGTH })
                  )
                  .required()
            : Yup.string(),
    });

    const owner = data ? getOwner(data) : null;
    const ownerId = owner?.ParticipantExternalId;
    const filledPrefixId = prefixes
        .find(
            (p: any) =>
                p.PhonePrefix ===
                data?.Participants?.find((p) => p?.ExternalId === ownerId)?.Contact?.PhonePrefix?.Prefix
        )
        ?.CountryId?.toString();
    const notAccessToMail = owner?.IdentityConfirmation ? !owner?.IdentityConfirmation?.HasAccessToEmail : false;

    // zobrazí blok pro zadání SMS kódu
    const setModeWaitingForClientSMS = () => setMode(ContactModes.WAIT_FOR_CLIENT_SMS_CODE);

    // zadaný mobil ve formuláři je již zaregistrován u draftu
    const clientPhoneRegisterSended = (values: any) =>
        phoneNumberRegistered === values?.phoneNumberPrefix?.label + values?.phoneNumber;

    // odešlu klientovi SMS kód, pro potvrzení tel. čísla
    const sendRegisterClientPhone = async (values: any) => {
        if (data) {
            return new Promise((resolve, reject) => {
                callRegisterClientPhoneNumber({
                    data: {
                        onlinePolicyExternalId: data.ExternalId,
                        phoneNumberPrefix: values?.phoneNumberPrefix?.label,
                        phoneNumber: values?.phoneNumber,
                    },
                    token,
                })
                    .then((response) => response.data)
                    .then(async (result: CallRegisterClientPhoneNumberResponse) => {
                        // uchování dat stránky
                        await setIllustrationSettings({
                            ContactPhoneNumberRegistered: values?.phoneNumberPrefix?.label + values?.phoneNumber,
                            ContactPhoneNumberRegisterResponse: result,
                        });
                        setDisableResend();
                        resolve(true);
                    })
                    .catch(() => reject(false));
            });
        }
    };

    const sendClientPhoneRegistrationSuccess = () => {
        setModeWaitingForClientSMS();
        myFormikRef && myFormikRef.current?.setTouched({}, false);
    };

    const clientPhoneRegistrationProcess = async (values: any, newIllustrationSettings: any) => {
        // telefonní číslo již bylo dávno ověřeno
        if (clientPhoneConfirmedSended(values)) {
            onContinueToNextStep(values, newIllustrationSettings);
            return;
        }

        // SMS na telefon ještě nešla
        if (!clientPhoneRegisterSended(values)) {
            if (await sendRegisterClientPhone(values)) {
                sendClientPhoneRegistrationSuccess();
            } else {
                await handleChangeContacts();
            }
        } else {
            sendClientPhoneRegistrationSuccess();
        }
    };

    const clientEmailVerificationSended = (values: any) =>
        emailRegistered !== undefined && emailRegistered === values?.email;

    const sendClientEmailVerificationSuccess = async (values: any) => {
        // uchování dat stránky
        await setIllustrationSettings({
            ContactEmailRegistered: values?.email,
            ContactFormSubmitted: true,
        });
        // mod čekání na souhlasy od klienta
        setMode(ContactModes.WAIT_FOR_CLIENT_CONSENTS);
    };

    const sendClientEmailVerification = async (values: any) => {
        if (data) {
            return new Promise((resolve, reject) => {
                callCreateEmailVerification({
                    data: {
                        onlinePolicyExternalId: data.ExternalId,
                        confirmationEmail: values?.email,
                    },
                    token,
                })
                    .then(() => resolve(true))
                    .catch(reject);
            });
        }
    };

    const clientEmailVerificationProcess = async (values: any) => {
        if (!clientEmailVerificationSended(values)) {
            if (await sendClientEmailVerification(values)) {
                await sendClientEmailVerificationSuccess(values);
            }
        }
    };

    const clientPhoneConfirmedSended = (values: any): boolean => {
        return phoneNumberConfirmed === values?.phoneNumberPrefix?.label + values?.phoneNumber;
    };

    const sendConfirmClientPhoneNumber = async (values: any, newIllustrationSettings: any) => {
        return new Promise((resolve, reject) => {
            // potvrzuji telefon
            callConfirmClientPhoneNumber({
                data: {
                    identificationValue: phoneNumberRegisterResponse.ConfirmationChannelIdentificationValue,
                    tokenExternalId: phoneNumberRegisterResponse.ConfirmationTokenId,
                    authCode: values?.smsCode,
                },
                token,
            })
                .then((response) => response.data)
                .then(async (result: CallConfirmClientPhoneNumberResponse) => {
                    if (result && result.IsOk) {
                        // další krok
                        onContinueToNextStep(values, {
                            ...newIllustrationSettings,
                            ContactPhoneNumberConfirmed: values?.phoneNumberPrefix?.label + values?.phoneNumber,
                        });
                        resolve(true);
                    } else {
                        setErrorSmsCode();
                    }
                })
                .catch((error) => {
                    setErrorSmsCode(error);
                    reject();
                });
        });
    };

    const refetchConsents = async (values: any): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            if (!token || !data) {
                resolve(false);
            }
            if (token && data) {
                callGetClientConsent({
                    data: {
                        onlinePolicyExternalId: data.ExternalId,
                        participantExternalId: data.Participants[0].ExternalId,
                    },
                    token,
                })
                    .then((response) => response.data)
                    .then(async (result) => {
                        // jsou uložené nějaké souhlasy
                        if (result.ClientConsents.length > 0) {
                            setSendConsent(false);
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    })
                    .catch((err) => reject(err));
            }
        });
    };

    const saveContactsToModel = (values: FormikValues, newIllustrationSettings: any) => {
        if (data && owner) {
            const participantId = owner.ParticipantExternalId;
            let mainParticipant = data?.Participants?.find((p) => p.ExternalId === participantId);
            const otherParticipants = data?.Participants?.filter((p) => p.ExternalId !== participantId);
            const countryCode = countries.find((c) => c.Id === parseInt(values?.phoneNumberPrefix?.value));

            if (mainParticipant) {
                mainParticipant = {
                    ...mainParticipant,
                    Contact: {
                        ...mainParticipant?.Contact,
                        Email: values?.email,
                        Phone: values?.phoneNumber,
                        PhonePrefix: {
                            $type: 'Eden.Paris.OnlineManagerService.SharedModels.Model.Identification.PhonePrefix, Eden.Paris.OnlineManagerService.SharedModels',
                            CountryCode: countryCode?.Code || '',
                            Prefix: values?.phoneNumberPrefix?.label,
                        },
                    },
                };

                if (!data.PolicyOwners[0].IdentityConfirmation) {
                    data.PolicyOwners[0].IdentityConfirmation = {
                        HasAccessToEmail: true,
                        IdentityConfirmationSourceType: null,
                        IdentityConfirmatorType: null,
                    };
                }

                data.PolicyOwners[0].IdentityConfirmation.HasAccessToEmail = !values?.noMailbox;

                const newModel = {
                    ...data,
                    Participants: [mainParticipant, ...otherParticipants],
                    Settings: {
                        ...data.Settings,
                        CurrentStepCode: getHigherStep(data.Settings?.CurrentStepCode, STEP_CODE.CONTACTS_CHECK),
                        IllustrationSettings: {
                            ...data?.Settings.IllustrationSettings,
                            ...(newIllustrationSettings || {}),
                        },
                    },
                };

                ctx.setCurrentModel(newModel);
                ctx.saveCurrentModel(newModel);
            }
        }
    };

    const onResendCode = (values: any) => {
        myFormikRef.current?.setFieldValue('smsCode', '');
        setResendEnabled(false);
        sendRegisterClientPhone(values);
        setDisableResend();
    };

    const onContinueToNextStep = (values: any, nextIllustrationSettings?: any) => {
        if (values) {
            saveContactsToModel(values, nextIllustrationSettings);
        }
        navigateTo(ROUTE.IDENTITY_VERIFICATION);
    };

    const setIllustrationSettings = async (newIllustrationSettings: any) => {
        const newModel = {
            ...data,
            Settings: {
                ...data?.Settings,
                IllustrationSettings: {
                    ...data?.Settings.IllustrationSettings,
                    ...newIllustrationSettings,
                },
            },
        };
        await ctx.saveCurrentModel(newModel as ModelProps);
        ctx.setCurrentModel(newModel as ModelProps);
    };

    const consentSaved = async (values: any) => (sendConsent && isF2F ? await refetchConsents(values) : true);

    return (
        <Layout
            continueDisabled={!isValid || !isContinueEnabled}
            footerContent={
                <FooterContent
                    requestSent={!!contactFormSent}
                    isContactFormDisabled={isContactFormDisabled}
                    handleChangeContacts={handleChangeContacts}
                />
            }
        >
            {data?.Settings ? (
                <LimitedAccess minStep={STEP_CODE.COVER_ADJUST}>
                    <Head heading1={t('pages.contact.title')} heading2={t('pages.contact.subtitle')} />

                    <CustomFormik<ContactDataType>
                        initialValues={{
                            email: participant?.Contact?.Email || '',
                            phoneNumberPrefix:
                                formattedPrefixes?.find((fp) => fp.value === filledPrefixId) ?? defaultPrefix,
                            phoneNumber:
                                data?.Participants?.find((p) => p.ExternalId === ownerId)?.Contact?.Phone ?? '',
                            smsCode: '',
                            noMailbox: notAccessToMail,
                        }}
                        onSubmit={async (v: ContactDataType | FormikValues | undefined) => {
                            setFormUnlock(false);
                            setShowErrorConsentsNotExists(false);

                            if (!formSubmitted) {
                                await setIllustrationSettings({ ContactFormSubmitted: true });
                            }

                            const hasAccessToEmail = !v?.noMailbox;

                            // formulář s kontakty ještě nebyl odeslán
                            if (!isContactFormSended(v)) {
                                setErrorInValidateContacts(null);
                                setErrorInExistingAgent(null);

                                // email byl odeslán
                                setContactFormSent({
                                    email: v?.email,
                                    phoneNumberPrefix: v?.phoneNumberPrefix,
                                    phoneNumber: v?.phoneNumber,
                                });

                                // kontrola, zda má klient jiné aktivní smlouvy
                                const anyActivePolicy = await callHasClientByContactAnyActivePolicy({
                                    data: {
                                        personBirthDate: participant.Identification.BirthDt,
                                        personEmail: v?.email,
                                        personPhone: v?.phoneNumber,
                                        personPhonePrefix: v?.phoneNumberPrefix.label,
                                    },
                                    token,
                                });

                                // kontrola zdali klient je agent
                                const existingAgent = await callIsContactExistingAgent({
                                    data: {
                                        email: v?.email,
                                        phone: v?.phoneNumber,
                                        phonePrefix: v?.phoneNumberPrefix.label,
                                        agentExternalId: ctx.userData?.extId ?? '',
                                    },
                                    token,
                                });

                                const anyErrors =
                                    anyActivePolicy?.data?.HasPersonBirthDateAnyActivePolicy === true ||
                                    anyActivePolicy?.data?.ExistAnyActivePolicyWithSameContact === true ||
                                    existingAgent?.data?.IsExistingAgent === true;

                                // smlouva má aktivní jiné smlouvy
                                // nebo klient je agent
                                if (
                                    anyActivePolicy?.data?.HasPersonBirthDateAnyActivePolicy === true ||
                                    anyActivePolicy?.data?.ExistAnyActivePolicyWithSameContact === true
                                ) {
                                    setErrorInValidateContacts(anyActivePolicy?.data);
                                    setContinueDisabled(false);
                                } else if (existingAgent?.data?.IsExistingAgent === true) {
                                    setErrorInExistingAgent(existingAgent?.data);
                                    setContinueDisabled(false);
                                }

                                // pokračování
                                // nejsou chyby nebo jsem na lokále
                                if (!anyErrors || isDevelopment()) {
                                    // F2F
                                    if (isF2F) {
                                        // má přístup do emailu
                                        if (hasAccessToEmail) {
                                            // odešlu verifikační email se souhlasy
                                            await clientEmailVerificationProcess(v);

                                            // mám souhlasy
                                            const consents = await consentSaved(v);
                                            if (consents) {
                                                await clientPhoneRegistrationProcess(v, { ContactFormSubmitted: true });
                                            }

                                            // souhlasy nebyly dosud uloženy
                                            if (!consents) {
                                                // mod čekání na souhlasy od klienta
                                                setMode(ContactModes.WAIT_FOR_CLIENT_CONSENTS);
                                            }
                                        }
                                        // nemá přístup k emailu
                                        if (!hasAccessToEmail) {
                                            await clientPhoneRegistrationProcess(v, { ContactFormSubmitted: true });
                                        }
                                    }

                                    // remote varianta
                                    if (isRemote) {
                                        // neposílám nic, rovnou další krok
                                        onContinueToNextStep(v, { ContactFormSubmitted: true });
                                    }
                                }
                            } else {
                                // formulář s kontakty už byl odeslán
                                // čeká se na souhlasy?
                                if (mode === ContactModes.WAIT_FOR_CLIENT_CONSENTS) {
                                    const consents = await consentSaved(v);
                                    if (consents) {
                                        await clientPhoneRegistrationProcess(v, { ContactFormSubmitted: true });
                                    } else {
                                        setShowErrorConsentsNotExists(true);
                                    }
                                }

                                // čeká se na zadání SMS kódu
                                if (mode === ContactModes.WAIT_FOR_CLIENT_SMS_CODE) {
                                    // je zadán kód
                                    if (
                                        phoneNumberRegisterResponse &&
                                        phoneNumberRegisterResponse.ConfirmationChannelIdentificationValue &&
                                        myFormikRef.current?.values.smsCode &&
                                        myFormikRef.current?.values.smsCode.toString().length === SMS_CODE_LENGTH
                                    ) {
                                        await sendConfirmClientPhoneNumber(v, { ContactFormSubmitted: true });
                                    }
                                }
                            }
                        }}
                        validationSchema={formSchema}
                        customRender
                        passedRef={myFormikRef}
                    >
                        {({ values }: FormikProps<any>) => {
                            return (
                                <Form noValidate>
                                    <DetectFormikChanged
                                        onChange={(v) => {
                                            setIsValid(prevalidateValues(v, formSchema, false));
                                            if (owner) {
                                                const participantId = owner.ParticipantExternalId;
                                                const countryCode = countries.find(
                                                    (c) => c.Id === parseInt(v?.phoneNumberPrefix?.value)
                                                );
                                                ctx.setCurrentModel({
                                                    ...data,
                                                    Participants: data.Participants.map((participant) => {
                                                        if (participant.ExternalId === participantId) {
                                                            return {
                                                                ...participant,
                                                                Contact: {
                                                                    ...participant.Contact,
                                                                    Email: v?.email || '',
                                                                    Phone: values?.phoneNumber || '',
                                                                    PhonePrefix: {
                                                                        $type: 'Eden.Paris.OnlineManagerService.SharedModels.Model.Identification.PhonePrefix, Eden.Paris.OnlineManagerService.SharedModels',
                                                                        CountryCode: countryCode?.Code || '',
                                                                        Prefix: values?.phoneNumberPrefix?.label,
                                                                    },
                                                                },
                                                            };
                                                        }
                                                        return participant;
                                                    }),
                                                    PolicyOwners: [
                                                        ...data.PolicyOwners.map((o, i) => {
                                                            if (i === 0) {
                                                                return {
                                                                    ...o,
                                                                    IdentityConfirmation: {
                                                                        ...o.IdentityConfirmation,
                                                                        HasAccessToEmail: !v.noMailbox,
                                                                    },
                                                                };
                                                            }
                                                            return o;
                                                        }),
                                                    ],
                                                });
                                            }
                                        }}
                                    />

                                    <ContactsForm disabled={isContactFormDisabled} />

                                    {mode === ContactModes.WAIT_FOR_CLIENT_CONSENTS && (
                                        <WaitingForClientConsents
                                            values={values}
                                            showErrorConsentsNotExists={showErrorConsentsNotExists}
                                            phoneNumberRegistered={clientPhoneRegisterSended(values)}
                                        />
                                    )}

                                    {errorInValidateContacts &&
                                        errorInValidateContacts?.HasPersonBirthDateAnyActivePolicy === true && (
                                            <Alert
                                                className="mb-4 mt-8"
                                                description={t<string>(
                                                    'pages.contactConfirmation.hasPersonBirthDateAnyActivePolicy',
                                                    {
                                                        policyNumbers:
                                                            errorInValidateContacts.ExistingPolicyNumbers.join(', '),
                                                    }
                                                )}
                                                data-test="hasPersonBirthDateAnyActivePolicyAlert"
                                            />
                                        )}

                                    {errorInValidateContacts &&
                                        errorInValidateContacts?.ExistAnyActivePolicyWithSameContact === true && (
                                            <Alert
                                                className="mb-4 mt-8"
                                                description={t<string>(
                                                    'pages.contactConfirmation.existAnyActivePolicyWithSameContact'
                                                )}
                                                data-test="existAnyActivePolicyWithSameContactAlert"
                                            />
                                        )}

                                    {errorInExistingAgent && (
                                        <Alert
                                            className="mb-4 mt-8"
                                            description={t<string>('pages.contactConfirmation.existingAgent')}
                                            data-test="existingAgentAlert"
                                        />
                                    )}

                                    {isSmsCodeRequired && (
                                        <WaitingForClientSMS values={values} onResendCode={onResendCode} />
                                    )}
                                </Form>
                            );
                        }}
                    </CustomFormik>
                    {/* <Fragment>
                        isValid: {isValid ? 'yes' : 'no'}
                        <br />
                        contactFormSent: {contactFormSent ? 'yes' : 'no'}
                        <br />
                        mode: {mode}
                        <br />
                        maxStepCode: {maxStepCode}
                        <br />
                        formSubmitted: {formSubmitted ? 'yes' : 'no'}
                        <br />
                        formValues.email: {formValues?.email !== '' ? 'yes' : 'no'}
                        <br />
                        formValues.phoneNumber: {formValues?.phoneNumber !== '' ? 'yes' : 'no'}
                        <br />
                        isF2F: {isF2F ? 'yes' : 'no'}
                        <br />
                        formUnlock: {formUnlock ? 'yes' : 'no'}
                        <br />
                        isContactFormDisabled: {isContactFormDisabled ? 'yes' : 'no'}
                        <br />
                        isContinueEnabled: {isContinueEnabled ? 'yes' : 'no'}
                        <br />
                        isSmsCodeRequired: {isSmsCodeRequired ? 'yes' : 'no'}
                        <br />
                    </Fragment> */}
                </LimitedAccess>
            ) : (
                <Loader />
            )}
        </Layout>
    );
};
