import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import Image from '../assets/images/person-pc.png';
import { Button } from '../components/common/Button';
import { Card } from '../components/common/Card';
import { Input } from '../components/common/Input';
import { Title } from '../components/common/Title';
import { Head } from '../components/Head';
import { CustomFormik } from '../components/CustomFormik';
import { callLoginAgentInit } from '../apis/authentications';
import { AppContext } from '../components/Context';
import { BaseErrorType } from '../types';
import { translateErrorCode } from '../utils';
import { Layout } from '../components/Layout';
import { ROUTE, SMS_CODE_LENGTH } from '../constants';

interface InitPasswordFormData {
    newPassword: string;
    confirmNewPassword: string;
    smsCode: string;
}

export const InitPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const ctx = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<BaseErrorType[]>([]);

    const validationSchema = Yup.object().shape({
        newPassword: Yup.string().required(),
        confirmNewPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), ''], t<string>('common.formErrors.passwordDontMatch'))
            .required(),
        smsCode: Yup.string().required(),
    });

    useEffect(() => {
        // v url není loginName
        if (!searchParams.get('loginName')) {
            navigate(ROUTE.ERROR_404);
        }
        // eslint-disable-next-line
    }, []);

    const newPasswordErrorMessage = useMemo(() => {
        const errorCode = errors?.[0]?.ErrorCode;
        return errorCode ? t(translateErrorCode(errorCode), 'common.formErrors.invalidPassword') : null;
    }, [errors, t]);

    return (
        <Layout withoutPolicy hideProgressBar hideSaveButton hideUserMenu hideFooter>
            <div className="flex w-full flex-col items-center">
                <Head heading1={t('pages.initPassword.title')} heading2={t('pages.initPassword.subtitle')} />

                <div className="flex w-full flex-col justify-center md:flex-row">
                    <img
                        src={Image}
                        alt=""
                        className="h-auto w-[9.375rem] grow-0 self-center md:w-[16rem] lg:w-[30rem]"
                        data-test="initPasswordImage"
                    />

                    <Card
                        className="w-full max-w-[30rem] p-4 md:w-full md:p-8 mdmax:mx-auto"
                        data-test="initPasswordCard"
                    >
                        <Title tag="h3" size="lg" fontWeight="medium" className="mb-6 ml-4">
                            {t('pages.initPassword.card.title')}
                        </Title>

                        <CustomFormik<InitPasswordFormData>
                            initialValues={{
                                newPassword: '',
                                confirmNewPassword: '',
                                smsCode: '',
                            }}
                            onSubmit={(values, actions) => {
                                setLoading(true);
                                callLoginAgentInit({
                                    data: {
                                        loginName: searchParams.get('loginName')!,
                                        oldPassword: values?.smsCode,
                                        newPassword: values?.newPassword,
                                    },
                                })
                                    .then(() => {
                                        actions?.resetForm();
                                        ctx.showPopup('init-password-done');
                                    })
                                    .catch((err) => {
                                        setErrors(err.response.data.Errors);
                                        if (err.response.data.Errors.length === 0) {
                                            ctx.showPopup('reset-password-error');
                                            actions?.resetForm();
                                        }
                                    })
                                    .finally(() => setLoading(false));
                            }}
                            validationSchema={validationSchema}
                        >
                            <div className="flex flex-col gap-y-4">
                                <Field
                                    component={Input}
                                    type="password"
                                    label={`${t('common.formFields.newPassword')}:`}
                                    name="newPassword"
                                />
                                <Field
                                    component={Input}
                                    type="password"
                                    label={`${t('common.formFields.confirmNewPassword')}:`}
                                    name="confirmNewPassword"
                                    errorMessage={newPasswordErrorMessage}
                                />
                                <Field
                                    component={Input}
                                    type="smsCode"
                                    name="smsCode"
                                    label={`${t('common.codeFromSms')}:`}
                                    maxLength={SMS_CODE_LENGTH}
                                />

                                <div className="flex justify-center">
                                    <Button
                                        isSubmit
                                        isDisabled={loading}
                                        onClick={() => setErrors([])}
                                        data-test="initPasswordButton"
                                    >
                                        {t('pages.initPassword.card.setPassword')}
                                    </Button>
                                </div>
                            </div>
                        </CustomFormik>
                    </Card>
                </div>
            </div>
        </Layout>
    );
};
