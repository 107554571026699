import dayjs from 'dayjs';
import { checkFullTextSearch } from '../../../../utils/hooks';
import { PolicyFilterType } from '../components/PolicyFilter';
import { Policy } from '../types';

export const getPolicyWritingAgent = (policy: Policy) => {
    if (!policy.OriginalWritingAgentLastName && !policy.OriginalWritingAgentFirstName) {
        return '-';
    }
    return `${policy.OriginalWritingAgentLastName || ''} ${policy.OriginalWritingAgentFirstName || ''}`;
};

export const getPolicyServicingAgent = (policy: Policy) => {
    if (!policy.PrimaryServicingAgentLastName && !policy.PrimaryServicingAgentFirstName) {
        return '-';
    }
    return `${policy.PrimaryServicingAgentLastName || ''} ${policy.PrimaryServicingAgentFirstName || ''}`;
};

export const filterPolicy = (policy: Policy, filter: PolicyFilterType): boolean => {
    if (filter.fulltext) {
        const policyDataContent = {
            OwnerName: `${policy.OwnerLastName} ${policy.OwnerFirstName}`,
            PolicyNo: policy.PolicyNo,
        };

        if (!checkFullTextSearch(policyDataContent, filter.fulltext)) {
            return false;
        }
    }

    if (filter.status.length > 0) {
        const found = filter.status.filter((s) => s.value === policy.PolicyStatusCode);
        if (found.length === 0) {
            return false;
        }
    }

    if (filter.policyNumber) {
        if (!checkFullTextSearch(policy.PolicyNo, filter.policyNumber)) {
            return false;
        }
    }

    if (filter.policyholder) {
        if (!checkFullTextSearch(`${policy.OwnerLastName} ${policy.OwnerFirstName}`, filter.policyholder)) {
            return false;
        }
    }

    if (filter.createdDateFrom) {
        if (dayjs(policy.IllustrationDt).isBefore(dayjs(filter.createdDateFrom))) {
            return false;
        }
    }

    if (filter.createdDateTo) {
        if (dayjs(policy.IllustrationDt).isAfter(dayjs(filter.createdDateTo).add(1, 'day'))) {
            return false;
        }
    }

    if (filter.beginningDateFrom) {
        if (dayjs(policy.BeginningDt).isBefore(dayjs(filter.beginningDateFrom))) {
            return false;
        }
    }

    if (filter.beginningDateTo) {
        if (dayjs(policy.BeginningDt).isAfter(dayjs(filter.beginningDateTo).add(1, 'day'))) {
            return false;
        }
    }

    if (filter.writingAgent.length > 0) {
        if (!filter.writingAgent.find((a) => a.value === getPolicyWritingAgent(policy))) {
            return false;
        }
    }

    if (filter.servicingAgent.length > 0) {
        if (!filter.servicingAgent.find((a) => a.value === getPolicyServicingAgent(policy))) {
            return false;
        }
    }

    return true;
};
