import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomFormik } from '../../components/CustomFormik';
import { Head } from '../../components/Head';
import { Card } from '../../components/common/Card';
import { Title } from '../../components/common/Title';
import { List } from '../../components/common/List';
import MojeIdLogo from '../../assets/images/mojeid.png';
import { AppContext } from '../../components/Context';
import { Icon } from '../../components/Icon';
import { Layout } from '../../components/Layout';

export const ClientMojeId = () => {
    const { t } = useTranslation();
    const pageTranslation: any = t('pages.clientMojeId', { returnObjects: true });
    const ctx = useContext(AppContext);

    return (
        <Layout hideNavigation hideProgressBar hideSaveButton hideUserMenu>
            <Head
                heading1={t('pages.clientMojeId.title')}
                heading2={t('pages.clientMojeId.subtitle')}
                afterHeadingContent={<img className="ml-3 h-auto w-[8.125rem]" alt="" src={MojeIdLogo} />}
            />

            <CustomFormik initialValues={{}} onSubmit={(v: any) => console.log(v)} className="flex flex-col gap-y-10">
                <div className="flex flex-col gap-y-10">
                    <Card data-test="mojeIdCard">
                        <div className="mb-8 inline-flex items-center justify-center gap-x-6">
                            <Title tag="h2" size="md" fontWeight="medium" className="mx-0" data-test="mojeIdTitle">
                                {t('pages.clientMojeId.card.title')}
                                <Icon
                                    name="question"
                                    className="ml-10 cursor-pointer"
                                    onClick={() => {
                                        ctx.showPopup('mojeid');
                                    }}
                                    data-test="mojeIdIcon"
                                />
                            </Title>
                        </div>

                        <List type="ordered" items={pageTranslation.card.list} data-test="mojeIdList" />
                        <p className="mt-6 pl-10" data-test="mojeIdSuccessText">
                            {pageTranslation.card.successText}
                        </p>
                    </Card>
                </div>
            </CustomFormik>
        </Layout>
    );
};
