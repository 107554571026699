import { ROUTE } from './constants';

export const config = {
    // HIDDEN_MENU_PATHS: [
    //     ROUTE.CLIENT_VERIFY_SNAP,
    //     ROUTE.CLIENT_VERIFY_IDENTITY,
    //     ROUTE.CLIENT_VERIFY_IDENTITY_FINAL,
    //     ROUTE.CLIENT_IDENTIFICATION_FINAL,
    //     ROUTE.DESIGN_SYSTEM,
    //     ROUTE.LOGIN,
    //     ROUTE.RESET_PASSWORD,
    //     ROUTE.INIT_PASSWORD,
    //     ROUTE.CONFIRMATION_DATA,
    //     ROUTE.CLIENT_MOJE_ID,
    //     ROUTE.CLIENT_PERSONAL_DATA,
    //     ROUTE.CONTACTS_CHECK_APPROVE,
    //     ROUTE.INVALID_LINK,
    //     ROUTE.ERROR_404,
    //     ROUTE.CONTINUE_DRAFT,
    // ],
    // DISABLED_MENU_PATHS: [ROUTE.SUMMARY_PREPARING],
    // HIDDEN_HEADERNAV_PATHS: [
    //     ROUTE.CLIENT_VERIFY_SNAP,
    //     ROUTE.CLIENT_VERIFY_IDENTITY,
    //     ROUTE.CLIENT_VERIFY_IDENTITY_FINAL,
    //     ROUTE.CLIENT_IDENTIFICATION_FINAL,
    //     ROUTE.RESET_PASSWORD,
    //     ROUTE.INIT_PASSWORD,
    //     ROUTE.DESIGN_SYSTEM,
    //     ROUTE.LOGIN,
    //     ROUTE.CHANGE_PASSWORD,
    //     ROUTE.SUMMARY_FAIL,
    //     ROUTE.CONTACTS_CHECK_APPROVE,
    //     ROUTE.CLIENT_MOJE_ID,
    //     ROUTE.DASHBOARD,
    //     ROUTE.DASHBOARD_MAIN,
    //     ROUTE.DASHBOARD_MY_POLICY,
    //     ROUTE.DASHBOARD_AGENCY_POLICY,
    //     ROUTE.DASHBOARD_DRAFTS,
    //     ROUTE.DASHBOARD_MY_CLIENTS,
    //     ROUTE.DASHBOARD_AGENCY_CLIENTS,
    //     ROUTE.DASHBOARD_CONTACTS,
    //     ROUTE.CONFIRMATION_DATA,
    //     ROUTE.UNACCESSIBLE,
    //     ROUTE.ERROR_404,
    //     ROUTE.CONTINUE_DRAFT,
    // ],
    // HIDDEN_HEADERALLNAV_PATHS: [
    //     ROUTE.CONFIRMATION_DATA,
    //     ROUTE.CLIENT_MOJE_ID,
    //     ROUTE.CLIENT_PERSONAL_DATA,
    //     ROUTE.CLIENT_VERIFY_IDENTITY,
    //     ROUTE.CLIENT_VERIFY_SNAP,
    //     ROUTE.CLIENT_VERIFY_IDENTITY_FINAL,
    //     ROUTE.INVALID_LINK,
    //     ROUTE.ERROR_404,
    // ],
    // HIDDEN_FOOTER_PATHS: [
    //     ROUTE.LOGIN,
    //     ROUTE.INIT_PASSWORD,
    //     ROUTE.CONCLUSION,
    //     ROUTE.RESET_PASSWORD,
    //     ROUTE.PACKAGE_CHOICE,
    //     ROUTE.ERROR_500,
    //     ROUTE.ERROR_404,
    //     ROUTE.UNACCESSIBLE,
    //     ROUTE.CLIENT_VERIFY_SNAP,
    //     ROUTE.DESIGN_SYSTEM,
    //     ROUTE.SUMMARY_PREPARING,
    //     ROUTE.SUMMARY_FAIL,
    //     ROUTE.CONTACTS_CHECK_APPROVE,
    //     ROUTE.CHANGE_PASSWORD,
    //     ROUTE.DASHBOARD,
    //     ROUTE.DASHBOARD_MAIN,
    //     ROUTE.DASHBOARD_MY_POLICY,
    //     ROUTE.DASHBOARD_AGENCY_POLICY,
    //     ROUTE.DASHBOARD_DRAFTS,
    //     ROUTE.DASHBOARD_MY_CLIENTS,
    //     ROUTE.DASHBOARD_AGENCY_CLIENTS,
    //     ROUTE.DASHBOARD_CONTACTS,
    //     ROUTE.INVALID_LINK,
    //     ROUTE.CONTINUE_DRAFT,
    // ],
    // SHOW_SAVE_BUTTON_PATHS: [
    //     ROUTE.COVER_ADJUST,
    //     ROUTE.CONTACTS_CHECK,
    //     ROUTE.IDENTITY_VERIFICATION,
    //     ROUTE.MOJE_ID,
    //     ROUTE.DOCUMENT_PHOTO,
    //     ROUTE.ID_DETAILS,
    //     ROUTE.PERSONAL_DATA,
    //     ROUTE.QUESTIONNAIRE,
    //     ROUTE.BENEFICIARIES,
    //     ROUTE.SUMMARY,
    // ],
    PATHS_WITHOUT_POLICY: [
        ROUTE.LOGIN,
        ROUTE.RESET_PASSWORD,
        ROUTE.CHANGE_PASSWORD,
        ROUTE.INIT_PASSWORD,
        ROUTE.DESIGN_SYSTEM,
        ROUTE.DASHBOARD,
        ROUTE.DASHBOARD_MAIN,
        ROUTE.DASHBOARD_MY_POLICY,
        ROUTE.DASHBOARD_AGENCY_POLICY,
        ROUTE.DASHBOARD_DRAFTS,
        ROUTE.DASHBOARD_MY_CLIENTS,
        ROUTE.DASHBOARD_AGENCY_CLIENTS,
        ROUTE.DASHBOARD_CONTACTS,
        ROUTE.DASHBOARD_KNOWLEDGE,
        ROUTE.CONCLUSION,
    ] as Array<string>,

    NON_AUTH_PROTECTED_PATHS: [
        ROUTE.CLIENT_VERIFY_SNAP,
        ROUTE.CLIENT_VERIFY_IDENTITY,
        ROUTE.CLIENT_VERIFY_IDENTITY_FINAL,
        ROUTE.CLIENT_IDENTIFICATION_FINAL,
        ROUTE.DESIGN_SYSTEM,
        ROUTE.LOGIN,
        ROUTE.RESET_PASSWORD,
        ROUTE.INIT_PASSWORD,
        ROUTE.CONFIRMATION_DATA,
        ROUTE.CLIENT_MOJE_ID,
        ROUTE.CLIENT_PERSONAL_DATA,
        ROUTE.CONTACTS_CHECK_APPROVE,
        ROUTE.INVALID_LINK,
        ROUTE.ERROR_404,
    ],

    MAX_SUMMARY_COUNTDOWN: 300, //seconds
    SUMMARY_COUNTDOWN: 120, //seconds
    SUMMARY_FETCH_INTERVAL: 5, // seconds

    // These values refer to the polish ID card aspect ratio 100:63
    CAPTURE_PHOTO_WIDTH: 1000,
    CAPTURE_PHOTO_HEIGHT: 630,
    CAPTURE_PHOTO_CLS: 'aspect-[100/63]',

    BENEFICIARIES_LIMIT: 10,

    MIN_AGE: 18,
    MAX_AGE: 65,
    MAX_START_INSURANCE_DAYS: 30,
    PASSWORD_RESET_LIMIT: 300,
    MIN_BENEFICIARY_SHARE: 10,
    MAX_DOCUMENT_AGE: 23,
    MAX_INIT_DATA_AGE_HOURS: 24,
    MAX_UPLOAD_SIZE: 20000000, // 20MB
    MAX_INPUT_LENGTH_DEFAULT: 100,
    MAX_INACTIVITY_POPUP_TIME: 300, // 5mins
    MAX_INACTIVITY_TOTAL_TIME: 900, // 15mins

    TEST_MAX_INACTIVITY_POPUP_TIME: 5,
    TEST_MAX_INACTIVITY_TOTAL_TIME: 20,
};
