import { Children, ReactElement, ReactNode, cloneElement, useMemo, useRef, useState } from 'react';

import { useOutsideClickChecker } from '../utils/hooks';
import { Icon } from './Icon';

interface FooterMobileMenuProps {
    control?: ReactElement;
    children?: ReactNode;
}

const FooterMobileMenu = ({ control, children }: FooterMobileMenuProps) => {
    const [openMenu, setOpenMenu] = useState(false);
    const wrapperRef = useRef<HTMLDivElement | null>(null);

    const childrenArray = useMemo(() => Children.toArray(children), [children]);

    const toggleOpenMenu = () => {
        setOpenMenu((prev) => !prev);
    };

    useOutsideClickChecker(wrapperRef, () => {
        setOpenMenu(false);
    });

    return (
        <div ref={wrapperRef}>
            {control &&
                cloneElement(control, {
                    onClick: toggleOpenMenu,
                })}
            {openMenu && (
                <div className="absolute bottom-full left-0 flex w-[20rem] -translate-y-[0.5rem] flex-col rounded-xs border-1 border-lightGray bg-white px-2 py-2">
                    {childrenArray.map((child: any, index: number) =>
                        cloneElement(child, {
                            className: index < childrenArray.length - 1 && 'border-b-1 border-gray',
                            onClick: () => {
                                child.props.onClick && child.props.onClick();
                                toggleOpenMenu();
                            },
                        })
                    )}

                    <Icon
                        name="close"
                        className="absolute right-1 top-1 cursor-pointer text-purple hover:text-darkPurple"
                        onClick={() => setOpenMenu(false)}
                        data-test="mobileMenuButton"
                    />
                </div>
            )}
        </div>
    );
};

export default FooterMobileMenu;
