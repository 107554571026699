import { useContext, useRef, useState } from 'react';
import { Form, FormikProps, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { CustomFormik } from '../../components/CustomFormik';
import { Head } from '../../components/Head';
import { AppContext } from '../../components/Context';
import { ArrangementKind } from '../../types/enums/ArrangementKind';
import { IdentityConfirmationSourceType } from '../../types/enums/IdentityConfirmationSourceType';
import { getHigherStep, getOwner } from '../../utils';
import Loader from '../../components/common/Loader';
import { useAppNavigate, useContextHelpers } from '../../utils/hooks';
import { callGenerateOnlinePolicyAttachmentCode } from '../../apis/documents';
import { ROUTE, STEP_CODE } from '../../constants';
import IdentityVerificationForm from './IdentityVerificationForm';
import LimitedAccess from '../../components/LimitedAccess';
import { Layout } from '../../components/Layout';
import { DetectFormikChanged } from '../../components/common/DetectFormikChanged';
import { IdentityConfirmatorType } from '../../types/enums/IdentityConfirmatorType';

export const IdentityVerification = () => {
    const { t } = useTranslation();
    const ctx = useContext(AppContext);
    const { isF2F, isRemote, withoutDocumentPhotos } = useContextHelpers();
    const { navigateTo } = useAppNavigate();
    const [isValid, setIsValid] = useState(false);
    const [continueDisabled, setContinueDisabled] = useState(false);
    const data = ctx.currentModel;
    const policyOwner = data ? getOwner(data) : null;
    const token = localStorage.getItem('token') || '';
    const myFormikRef = useRef<FormikProps<any>>(null);

    const formSchema: any = Yup.object().shape({
        declaration: isRemote
            ? Yup.boolean()
            : Yup.boolean()
                  .required()
                  .oneOf([true], t('common.formErrors.requiredField') || ''),
        personalData: Yup.string().test(t('common.formErrors.requiredField'), function (value) {
            const { declaration } = this.parent;
            return withoutDocumentPhotos || (declaration && !!value);
        }),
        confirmatorType: Yup.string().test(t('common.formErrors.requiredField'), function (value) {
            return this.parent.personalData === '1' || withoutDocumentPhotos ? true : value === '1' || value === '2';
        }),
    });

    return (
        <Layout continueDisabled={continueDisabled || !isValid}>
            {data ? (
                <LimitedAccess minStep={STEP_CODE.CONTACTS_CHECK}>
                    <Head heading1={t('pages.identityVerification.title')} />

                    <CustomFormik
                        initialValues={{
                            declaration: !!policyOwner?.IdentityConfirmation?.IdentityConfirmationSourceType ?? false,
                            personalData:
                                policyOwner?.IdentityConfirmation?.IdentityConfirmationSourceType?.toString() ?? '',
                            confirmatorType:
                                policyOwner?.IdentityConfirmation?.IdentityConfirmatorType?.toString() ?? '',
                        }}
                        onSubmit={(v) => {
                            setContinueDisabled(true);
                            if (token) {
                                const lastConfirmator =
                                    data.Settings?.IllustrationSettings?.IdentityConfirmatorType ?? '';

                                const newData = {
                                    ...data,
                                    Settings: {
                                        ...data.Settings,
                                        IllustrationSettings: {
                                            ...data?.Settings.IllustrationSettings,
                                            IdentityConfirmatorType: v?.confirmatorType,
                                        },
                                        CurrentStepCode: getHigherStep(
                                            data.Settings.CurrentStepCode,
                                            STEP_CODE.IDENTITY_VERIFICATION
                                        ),
                                    },
                                };

                                if (withoutDocumentPhotos) {
                                    newData.PolicyOwners[0].IdentityConfirmation.IdentityConfirmatorType =
                                        IdentityConfirmatorType.Agent;
                                }

                                ctx.setCurrentModel(newData);
                                ctx.saveCurrentModel(newData);

                                if (withoutDocumentPhotos) {
                                    navigateTo(ROUTE.ID_DETAILS);
                                } else if (v?.personalData === IdentityConfirmationSourceType.MojeId.toString()) {
                                    navigateTo(ROUTE.MOJE_ID);
                                } else if (v?.personalData === IdentityConfirmationSourceType.Photocopy.toString()) {
                                    setContinueDisabled(true);

                                    if (isRemote) {
                                        if (v.confirmatorType !== lastConfirmator?.toString() || !lastConfirmator) {
                                            // odeslání SMS s pokyny k naskenování dokumentů, 1 agentovi, 2 klientovi
                                            callGenerateOnlinePolicyAttachmentCode({
                                                data: {
                                                    onlinePolicyExternalId: data.ExternalId,
                                                    generateOnlinePolicyAttachmentCodePlType: parseInt(
                                                        v.confirmatorType
                                                    ),
                                                },
                                                token,
                                            })
                                                .then((response) => response.data)
                                                .catch((e) => console.error(e))
                                                .finally(() => {
                                                    setContinueDisabled(false);
                                                    navigateTo(ROUTE.DOCUMENT_PHOTO);
                                                });
                                        } else {
                                            setContinueDisabled(false);
                                            navigateTo(ROUTE.DOCUMENT_PHOTO);
                                        }
                                    } else if (isF2F) {
                                        if (v.confirmatorType !== lastConfirmator?.toString() || !lastConfirmator) {
                                            callGenerateOnlinePolicyAttachmentCode({
                                                data: {
                                                    onlinePolicyExternalId: data.ExternalId,
                                                    generateOnlinePolicyAttachmentCodePlType: parseInt(
                                                        v.confirmatorType
                                                    ),
                                                },
                                                token,
                                            })
                                                .then((response) => response.data)
                                                .catch((e) => console.error(e))
                                                .finally(() => {
                                                    setContinueDisabled(false);
                                                    navigateTo(ROUTE.DOCUMENT_PHOTO);
                                                });
                                        } else {
                                            setContinueDisabled(false);
                                            navigateTo(ROUTE.DOCUMENT_PHOTO);
                                        }
                                    }
                                }
                            }
                        }}
                        className="flex flex-col gap-y-10"
                        customRender
                        validationSchema={formSchema}
                        passedRef={myFormikRef}
                    >
                        {({ values, onChange }: FormikValues) => {
                            return (
                                <Form noValidate onChange={onChange}>
                                    <DetectFormikChanged
                                        onChange={(v: FormikValues) => {
                                            if (formSchema.isValidSync(v) !== isValid) {
                                                setIsValid(formSchema.isValidSync(v));
                                            }

                                            ctx.setCurrentModel({
                                                ...data,
                                                PolicyOwners: [
                                                    {
                                                        ...data.PolicyOwners[0],
                                                        IdentityConfirmation: {
                                                            ...data.PolicyOwners[0].IdentityConfirmation,
                                                            IdentityConfirmationSourceType:
                                                                parseInt(v?.personalData) || v?.declaration,
                                                            IdentityConfirmatorType: parseInt(v?.confirmatorType),
                                                        },
                                                    },
                                                ],
                                                Settings: {
                                                    ...data.Settings,
                                                    IsIdentityConfirmed:
                                                        data.Settings.ArrangementType === ArrangementKind.F2F,
                                                },
                                            });
                                        }}
                                    />

                                    <IdentityVerificationForm values={values} />
                                </Form>
                            );
                        }}
                    </CustomFormik>
                </LimitedAccess>
            ) : (
                <Loader />
            )}
        </Layout>
    );
};
