import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomFormik } from '../../components/CustomFormik';
import { Head } from '../../components/Head';
import { Card } from '../../components/common/Card';
import { Title } from '../../components/common/Title';
import { List } from '../../components/common/List';
import MojeIdLogo from '../../assets/images/mojeid.png';
import { AppContext } from '../../components/Context';
import { Icon } from '../../components/Icon';
import { ROUTE } from '../../constants';
import { ArrangementKind } from '../../types/enums/ArrangementKind';
import { callCheckIdcardExistence } from '../../apis/support';
import { useAppNavigate } from '../../utils/hooks';
import Alert from '../../components/Alert';
import { Layout } from '../../components/Layout';
import FooterContent from './FooterContent';

export const MojeId = () => {
    const { t } = useTranslation();
    const pageTranslation: any = t('pages.mojeId', { returnObjects: true });
    const [errorNotAllowContinue, setErrorNotAllowContinue] = useState(false);
    const [continueDisabled, setContinueDisabled] = useState(false);
    const { navigateTo } = useAppNavigate();
    const token = localStorage.getItem('token');

    const ctx = useContext(AppContext);
    const data = ctx.currentModel;

    const conclusionType = data?.Settings.ArrangementType;
    const isF2F = conclusionType === ArrangementKind.F2F;

    return (
        <Layout
            continueText={isF2F ? t<string>('common.clientFinishedMojeId') : undefined}
            continueDisabled={continueDisabled}
            footerContent={<FooterContent />}
        >
            <Head
                heading1={t('pages.mojeId.title')}
                heading2={t('pages.mojeId.subtitle')}
                afterHeadingContent={<img className="ml-3 h-auto w-[8.125rem]" alt="" src={MojeIdLogo} />}
            />

            <CustomFormik
                initialValues={{}}
                onSubmit={(v: any) => {
                    if (token && data) {
                        // F2F
                        if (isF2F) {
                            setContinueDisabled(true);
                            setErrorNotAllowContinue(false);
                            callCheckIdcardExistence({
                                data: {
                                    onlinePolicyExternalId: data.ExternalId,
                                },
                                token,
                            })
                                .then((response) => response.data)
                                .then((result) => {
                                    if (result.Exists) {
                                        window.open(
                                            ROUTE.CLIENT_IDENTIFICATION_FINAL,
                                            '_blank',
                                            'rel=noopener noreferrer'
                                        );
                                        navigateTo(ROUTE.ID_DETAILS);
                                    } else {
                                        setErrorNotAllowContinue(true);
                                    }
                                })
                                .finally(() => {
                                    setContinueDisabled(false);
                                });
                        } else {
                            // Remote varianta
                            window.open(ROUTE.CLIENT_IDENTIFICATION_FINAL, '_blank', 'rel=noopener noreferrer');
                            navigateTo(ROUTE.ID_DETAILS);
                        }
                    }
                }}
            >
                <div className="flex flex-col">
                    <Card data-test="mojeIdCard">
                        <div className="inline-flex items-center justify-center gap-x-6">
                            <Title tag="h2" size="md" fontWeight="medium" className="mx-0" data-test="mojeIdTitle">
                                {t('pages.mojeId.card.title')}
                                <Icon
                                    name="question"
                                    className="ml-10 cursor-pointer"
                                    onClick={() => {
                                        ctx.showPopup('mojeid');
                                    }}
                                    data-test="mojeIdIcon"
                                />
                            </Title>
                        </div>

                        <List type="ordered" items={pageTranslation.card.list} data-test="mojeIdList" />
                        <p className="mt-6 pl-10" data-test="mojeIdSuccess">
                            {pageTranslation.card.successText}
                        </p>
                    </Card>

                    {errorNotAllowContinue && (
                        <Alert
                            className="mb-4 mt-8"
                            description={t<string>('pages.mojeId.notAllowContinue')}
                            data-test="mojeIdAlert"
                        />
                    )}
                </div>
            </CustomFormik>
        </Layout>
    );
};
