import { FormikValues } from 'formik';
import { matchPath } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import { DATE_FORMAT, ROUTE, TEST_API_URL } from '../constants';
import {
    KeyValueProps,
    SizesType,
    ErrorType,
    ValidationResultItemType,
    ValidationResultType,
    UserDataType,
    CountryType,
    BaseListType,
    ReactSelectItemType,
    IdentificationType,
    PolicyStatusType,
} from '../types';

import {
    AddressProps,
    ModelProps,
    SuggestedProtectionGroupProps,
    SuggestedProtectionVariantProps,
} from '../types/model';
import { RelationshipKind } from '../types/enums/RelationshipKind';
import { RiderGroupFamiliesProps } from '../pages/PackageAdjustment/PackageAdjustment';
import { RouteProps, routes } from '../routes';
import { config } from '../config';
import { SexKind } from '../types/enums/SexKind';

// Maps input value (v) according to its range (inMin, inMax) into output range (outMin, outMax)
// Example: v = 50; inMin = 0; inMax = 100, outMin = 1000; outMax = 2000; => return 1500
export const scaleValues = (v: number, inMin: number, inMax: number, outMin: number, outMax: number) => {
    return ((v - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
};

// Checks if array exists, not empty and it is really an array
export const checkArray = (arr?: Array<unknown> | null) => {
    return arr && Array.isArray(arr) && arr.length > 0;
};

// Returns tailwind classname according to passed size
export const getTextClassname = (size: SizesType) => {
    switch (size) {
        case 'lg':
            return 'text-md md:text-lg';
        case 'md':
            return 'text-sm md:text-md';
        case 'sm':
            return 'text-xs md:text-sm';
        case 'xs':
            return 'text-xs';
        default:
            break;
    }
};

// Stores (overwrites) values into local storage
export const storeValues = (objectName: string, v?: FormikValues) => {
    window.localStorage.setItem(objectName, JSON.stringify(v));
};

// Gets values from local storage
export const getStoredValues = (objectName: string) => {
    const vals = window.localStorage.getItem(objectName);
    return vals && JSON.parse(vals);
};

// Deletes values from local storage
export const deleteStoredValues = (objectName: string) => {
    window.localStorage.removeItem(objectName);
};

// Return only content of base64
export const getBase64FileContent = (fileContent: string): string => {
    const splitted = fileContent.split(',');
    if (splitted.length > 1) {
        return splitted[1];
    }
    return fileContent;
};

// Converts image or pdf file to base64
export const getBase64 = (file?: File) => {
    const reader = new FileReader();
    const ext = getFileExtension(file?.name);

    return new Promise<string>((resolve, reject) => {
        if (file) {
            if (
                !(
                    (!file.type && ext === 'heic') ||
                    (!file.type && ext === 'heif') ||
                    file.type.match('image.*') ||
                    file.type.match('application/pdf')
                )
            ) {
                console.error('The selected file does not appear to be an image.');
                reject();
            }

            reader.readAsDataURL(file);
            reader.addEventListener('load', () => {
                resolve(reader.result as string);
            });
            reader.addEventListener('error', (error) => {
                console.error('Error: ', error);
                reject();
            });
        } else {
            reject();
        }
    });
};

// Converts number to time formatted as MM:SS
export const formatTime = (t: number) => {
    const m = Math.floor(t / 60);
    const s = t - m * 60;
    return { minutes: `0${m}`, seconds: `${s < 10 ? '0' : ''}${s}` };
};

// Extracts default form values object from passed suggested protections
export const getInitialValues = (data: Array<SuggestedProtectionGroupProps>) => {
    let result: KeyValueProps = {};
    if (data && Array.isArray(data)) {
        data.forEach((item) =>
            item.SuggestedProtections.forEach((sp) =>
                sp.Variants.forEach((v) => {
                    result[v.SuggestedRiderVersion.Code] = v.IsChosen;
                    result[`${v.SuggestedRiderVersion.Code}Slider`] = v.SumInsuredChosen;
                })
            )
        );
    }
    return result;
};

// Fetches fresh init data and stores into LS
export const getInitData = (locale: string) => {
    const token = localStorage.getItem('token');
    token &&
        axios
            .get(`${getBaseUrl()}api/online-poland/agent-web/initializations/init-data/${locale}/SIMPL_1_0`, {
                headers: {
                    'X-Simplea-Auth-Token': token,
                },
            })
            .then((res) => {
                const data = res.data;

                if (data) {
                    const val = JSON.stringify(data);
                    localStorage.setItem('initData', val);
                    localStorage.setItem('initDataFetched', dayjs().format());
                    window.dispatchEvent(new Event('storage'));
                }
            })
            .catch((err) => console.error(err));
};

// Scrolls smoothly to top of the page
export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
    });
};

export const scrollTo = (target: HTMLElement | null) => {
    if (window && target) {
        const y = target.getBoundingClientRect().top + window.scrollY - 120;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }
};

const getMultiplier = (index: number) => {
    switch (index % 4) {
        case 1:
            return 1;
        case 2:
            return 3;
        case 3:
            return 7;
        case 0:
            return 9;
        default:
            try {
                throw new Error('error');
            } catch (e) {
                throw e;
            }
    }
};

export const validatePesel = (pesel: string): boolean => {
    if (pesel && pesel.length === 11) {
        // Split whole pesel into Array(11)
        const peselArr = pesel.split('');
        let total = 0;

        // Go through peselArray and multiply its value, add it to the total
        // PESEL: ABCDEFGHIJK
        // Multipliers A*1 + B*3 + C*7 + D*9 + E*1 + F*3 + G*7 + H*9 + I*1 + J*3
        for (let i = 0; i < peselArr.length - 1; i++) {
            total += +peselArr[i] * getMultiplier(i + 1);
        }

        // Get modulo of total
        const mod = total % 10;

        // Compare it with last character of pesel
        const lastChar = Number(pesel.slice(pesel.length - 1));
        return (mod === 0 && lastChar === 0) || lastChar === 10 - mod;
    }
    return false;
};

// Parses pesel to date YYYY-MM-DD and compares with birth date YYYY-MM-DD
export const compareBirthDtPesel = (birthDt: string, pesel: string) => {
    // https://en.wikipedia.org/wiki/PESEL (Birthdates)
    const peselYear = pesel.slice(0, 2);
    const peselMonth = pesel.slice(2, 4);
    const peselDay = pesel.slice(4, 6);
    // I presume this app won't be useful in 2099+
    const millenniumCheckVal = parseInt(peselMonth) - 20;
    const isCurrentMillennium = millenniumCheckVal > 0;
    const year = `${isCurrentMillennium ? '20' : '19'}${peselYear}`;
    const month = isCurrentMillennium ? `${millenniumCheckVal < 10 ? '0' : ''}${millenniumCheckVal}` : peselMonth;
    const birthDtPesel = `${year}-${month}-${peselDay}`;

    return birthDt === birthDtPesel;
};

// Transform pesel to birth date
export const getBirthDtOfPesel = (pesel: string) => {
    if (!pesel || pesel?.length < 6) {
        return null;
    }

    // https://en.wikipedia.org/wiki/PESEL (Birthdates)
    const peselYear = pesel.slice(0, 2);
    const peselMonth = pesel.slice(2, 4);
    const peselDay = pesel.slice(4, 6);
    // I presume this app won't be useful in 2099+
    const millenniumCheckVal = parseInt(peselMonth) - 20;
    const isCurrentMillennium = millenniumCheckVal > 0;
    const year = `${isCurrentMillennium ? '20' : '19'}${peselYear}`;
    const month = isCurrentMillennium ? `${millenniumCheckVal < 10 ? '0' : ''}${millenniumCheckVal}` : peselMonth;
    return `${year}-${month}-${peselDay}`;
};

// Transform pesel to birth date
export const getSexKindOfPesel = (pesel: string) => {
    if (!pesel || pesel?.length < 10) {
        return null;
    }

    // https://en.wikipedia.org/wiki/PESEL (Format)
    // PESEL numbers have the form of YYMMDDZZZXQ, where X denotes sex (even numbers for females, odd numbers for males)
    const peselSex = pesel.slice(9, 10);
    return parseInt(peselSex) % 2 === 0 ? SexKind.Female : SexKind.Male;
};

// ### Function resolves base url for API calls, but returns test url on localhost

export const getBaseUrl = () => {
    if (window && window.location.origin) {
        const hostname = window.location.hostname;
        if (hostname === 'localhost' || hostname === '127.0.0.1') {
            return `${TEST_API_URL}/`;
        }
        return `${window.location.origin}/`;
    }
    return '/';
};

export const getToken = (): string | null => localStorage.getItem('token');

export const parseUserDataFromToken = (token: string): UserDataType | null => {
    try {
        const userData = jwtDecode(token) as any;
        if (!userData) {
            return null;
        }

        return {
            extId: userData['X-Simplea-Paris-User-ExtId'],
            loginName: userData['X-Simplea-Paris-User-LoginName'],
            name: `${userData['X-Simplea-Paris-User-FirstName']} ${userData['X-Simplea-Paris-User-LastName']}`,
            email: userData['X-Simplea-Paris-User-Email'],
            language: 'pl',
        };
    } catch {
        return null;
    }
};

export const isTokenExpired = (token: string): boolean => {
    try {
        const decodedToken = jwtDecode(token) as any;
        if (!decodedToken) {
            return true;
        }

        return dayjs().isAfter(dayjs.unix(decodedToken.exp));
    } catch {
        return true;
    }
};

export const parseValidationErrors = (result: ValidationResultType): ErrorType[] => {
    if (result?.ValidationResults) {
        return result?.ValidationResults?.map((err: ValidationResultItemType) => ({
            code: err.AttributeName,
            message: err.Message,
        }));
    }

    return [];
};

export const prepareJsonOnlineModelData = (modelIntialState: any, initData: any) => {
    const { Product } = initData;
    const participantId = uuidv4();

    // TODO: all values of uuidv4() function must be change by users inputs
    const cloneModel = {
        ...modelIntialState,
        IllustrationDt: nowDate(),
        ExternalId: uuidv4(),
        Participants: [
            {
                ...modelIntialState.Participants[0],
                ExternalId: participantId,
                ClientExternalId: uuidv4(),
                Identification: {
                    $type: null,
                    BirthDt: null,
                    CurrentAge: null,
                    Sex: null,
                },
            },
        ],
        PolicyOwners: [
            {
                ...modelIntialState.PolicyOwners[0],
                ExternalId: uuidv4(),
                ParticipantExternalId: participantId,
            },
        ],
        InsuredPersons: [
            {
                ...modelIntialState.InsuredPersons[0],
                ExternalId: uuidv4(),
                ParticipantExternalId: participantId,
            },
        ],
        Settings: {
            ...modelIntialState.Settings,
        },
        MarketingEvent: {
            ...modelIntialState.MarketingEvent,
            DiscountRating: 1,
        },
        ProductVersion: {
            ...modelIntialState.ProductVersion,
            Code: Product?.ProductVersionCode,
            Name: Product?.ProductVersionNameDefault,
            Product: {
                ...modelIntialState.ProductVersion.Product,
                FamilyName: Product?.ProductNameDefault,
                Code: Product?.ProductCode,
            },
            Limits: {
                ...modelIntialState.ProductVersion.Limits,
                ChildAgeMax: Product?.InitDataProductVersionLimit?.ChildAgeMax,
                InsurancePeriodMin: Product?.InitDataProductVersionLimit?.InsurancePeriodMin,
                LegalRepresentativeDemandShorteningInMonths:
                    Product?.InitDataProductVersionLimit?.LegalRepresentativeDemandShorteningInMonths,
                OwnerAgeMin: Product?.InitDataProductVersionLimit?.OwnerAgeMin,
            },
        },
    };
    return cloneModel;
};

export const subtractDate = (value: number, unit: dayjs.ManipulateType) => {
    return dayjs().subtract(value, unit).format(DATE_FORMAT);
};

export const subtractsDate = (items: Array<{ value: number; unit: dayjs.ManipulateType }>): string => {
    return items
        .reduce<dayjs.Dayjs>((acc: dayjs.Dayjs, item) => acc.subtract(item.value, item.unit), dayjs())
        .format(DATE_FORMAT);
};

export const addDate = (value: number, unit: dayjs.ManipulateType) => {
    return dayjs().add(value, unit).format(DATE_FORMAT);
};

export const nowDate = () => {
    return dayjs().format(DATE_FORMAT);
};

export const getKeyByValue = (object: Object, value: string) => {
    const indexOfS = Object.values(object).indexOf(value as unknown as object);
    const key = Object.keys(object)[indexOfS];
    return key;
};

export const getOwner = (data: ModelProps) => {
    return data.PolicyOwners?.[0];
};

export const getInsuredParticipant = (data: ModelProps) => {
    return data?.InsuredPersons?.[0];
};

export const getOwnerParticipant = (data: ModelProps, id: string) =>
    data?.Participants?.find((p) => p.ExternalId === id);

export const getBeneficiaries = (data: ModelProps) => {
    const result = data?.InsuredPersons?.[0]?.SuggestedInsurance.ProtectionGroups.find((pg) =>
        pg.SuggestedProtections.find((sp) => sp.Beneficiaries)
    );
    return result;
};

export const getPersonOfInsurableInterest = (data: ModelProps) => {
    const personInsurableData = data?.InsuredPersons?.[0]?.SuggestedInsurance?.ProtectionGroups?.find((pg) =>
        pg.SuggestedProtections.find((sp) => sp.PersonOfInsurableInterest)
    );

    if (personInsurableData) {
        return personInsurableData?.SuggestedProtections?.[0]?.PersonOfInsurableInterest;
    }

    return null;
};

export const getPersonInsurableGroups = (groups?: Array<RiderGroupFamiliesProps>) => {
    if (groups) {
        return groups
            .filter((g) => g.RiderGroups.find((rg) => rg.Riders.find((r) => r.IsWithPersonOfInsurableInterest)))
            .map((filtered) => filtered.Code);
    }

    return null;
};

export const getPartnerType = (data: ModelProps) => {
    const insurablePerson = getPersonOfInsurableInterest(data);

    if (insurablePerson) {
        const partnerParticipant = data.Participants.find(
            (p) => p?.ExternalId === insurablePerson?.ParticipantExternalId
        );

        if (partnerParticipant) {
            return 'Relationship' in partnerParticipant &&
                partnerParticipant.Relationship === RelationshipKind.HusbandWifePartner
                ? 'married'
                : 'partner';
        }
        return null;
    }

    if (data?.Settings?.IllustrationSettings?.PackageAdjustPartnerType) {
        if (['married', 'partner'].includes(data.Settings?.IllustrationSettings?.PackageAdjustPartnerType)) {
            return data.Settings?.IllustrationSettings?.PackageAdjustPartnerType;
        }
    }

    return null;
};

export const getValueInVariantRange = (value: number, variant: SuggestedProtectionVariantProps): number => {
    return value < variant.SumInsuredMin
        ? variant.SumInsuredMin
        : value > variant.SumInsuredMax
        ? variant.SumInsuredMax
        : value;
};

export const getCountriesSelectList = (initData: any) => {
    const countries: Array<CountryType> = initData?.Lovs?.Countries || [];
    const result = countries
        .sort((a, b) => a.OrderNo - b.OrderNo)
        .map((c) => ({ label: c.NameTranslated, value: c.Code }));
    return result;
};

// TODO: není možné vrátit prázné pole, namísto null ?
export const getIdentificatorSelectList = (initData: any) => {
    const ids: Array<IdentificationType> = initData?.Lovs?.IdentificationTypes;
    if (ids) {
        return ids.sort((a, b) => a.OrderNo - b.OrderNo).map((k) => ({ label: k.NameTranslated, value: k.Id }));
    }
    return null;
};

// TODO: není možné vrátit prázné pole, namísto null ?
export const getIdCardSelectList = (initData: any) => {
    const ids: Array<BaseListType> = initData?.Lovs?.IdCardTypes;
    if (ids) {
        return ids.filter((f) => f.Code.endsWith('_PL')).map((k) => ({ label: k.NameTranslated, value: k.Id }));
    }
    return null;
};

export const getPolicyStatusesSelectList = (initData: any): Array<ReactSelectItemType> => {
    return (initData?.Lovs?.PolicyStatuses || []).map((s: PolicyStatusType) => ({
        label: s.NameTranslated,
        value: s.Code,
    }));
};

// TODO: není možné vrátit prázné pole, namísto null ?
export const getSexSelectList = (initData: any) => {
    const ids: Array<BaseListType> = initData?.Lovs?.Sex;

    if (ids) {
        // remove SexKind.NotSpecified (Code 'U' Unspecified)
        return ids.filter((r) => r.Code !== 'U').map((k) => ({ label: k.NameTranslated, value: k.Id }));
    }
    return null;
};

export const getSelectValueByCode = (list?: Array<ReactSelectItemType> | null, code?: string | number | null) => {
    if (code && list) {
        return list.find((item) => item.value === code);
    }
    return null;
};

export const getCheckedPartnerGroups = (model: ModelProps, initData: any) => {
    const riderGroupFamilies = initData.RiderGroupFamilies as Array<RiderGroupFamiliesProps>;

    if (model && riderGroupFamilies) {
        // Get list of checked (at least one checkbox on Cover Adjustment) "partner/spouse" rider groups
        let arr: Array<string> = [];
        const personInsurableGroups = getPersonInsurableGroups(riderGroupFamilies);
        const relevantGroups = model.InsuredPersons[0].SuggestedInsurance.ProtectionGroups.filter(
            (pg) => personInsurableGroups?.includes(pg.RiderCategoryCode) && pg.RiderCategoryCode
        );

        relevantGroups.forEach((rg) =>
            rg.SuggestedProtections.forEach((sp) =>
                sp.Variants.forEach((v) => {
                    if (v.IsChosen === true && !arr.includes(rg.RiderCategoryCode)) {
                        arr.push(rg.RiderCategoryCode);
                    }
                })
            )
        );
        return arr;
    }

    return null;
};

// vrací pozici konkrétní stránky podle kódu
export const getStepPosition = (stepCode: string): number => {
    return routes
        .filter((r) => !!r.code)
        .map((r) => r.code)
        .indexOf(stepCode);
};

export const getStepPositionByPathName = (pathName: string): number => {
    return routes.findIndex((r: RouteProps) => matchPath(r.path, pathName));
};

// vrací routu na určité pozici
export const getRouteByStepPosition = (position: number): RouteProps | undefined => {
    const routesWithStepCode = routes.filter((r) => !!r.code);
    return routesWithStepCode?.[position];
};

// vrací cestu k routě podle kroku
export const getRouteByStepCode = (stepCode: string): RouteProps | undefined => {
    return routes.find((r) => r.code === stepCode);
};

// vrací routu která je po určitém kroku
export const getNextRouteByStepCode = (stepCode: string): RouteProps | undefined => {
    const position = getStepPosition(stepCode);
    if (position) {
        return getRouteByStepPosition(position + 1);
    }
    return undefined;
};

// vrací vyšší stránku z uvedených
export const getHigherStep = (currentStep: string, newStep: string): string => {
    const positionCurrent = getStepPosition(currentStep);
    const positionNew = getStepPosition(newStep);
    if (positionCurrent > positionNew) return currentStep;
    return newStep;
};

export const getFormatedAddress = (initData: any, address: AddressProps | null): string => {
    if (address == null) return '';
    return (
        address.StreetName +
        ' ' +
        address.HouseNo +
        ', ' +
        address.CityName +
        ' ' +
        address.Zip +
        ', ' +
        initData?.Lovs?.Countries?.find((country: CountryType) => country.Code === address.CountryCode).NameTranslated
    );
};

export const getFileExtension = (name?: string) => {
    if (name) {
        return name.split('.').pop();
    } else {
        return '';
    }
};

export const pushStateListener = () => window.history.pushState(null, document.title, window.location.href);

export const isDevelopment = () =>
    ['localhost', '127.0.0.1'].includes(window.location.hostname) || process.env.REACT_APP_ENV === 'development';

export const isAllowedFileType = (file: any) => {
    return (file?.type?.startsWith('image/') && file?.type !== 'image/webp') || isSpecialFileType(file);
};

export const isSpecialFileType = (file: any) => {
    return file?.type === 'application/pdf';
};

export const translateErrorCode = (errorCode: string, defaultTranslate: string = ''): string => {
    const translateKeys: any = {
        // účty
        // err_auth_acc_locked: '',
        // err_auth_acc_locked_for_too_many_attempts: '',
        // err_auth_acc_doesnt_exist: '',
        // err_auth_acc_exists_already: '',
        // err_auth_acc_cant_reset_due_first_login_missing: '',
        // autorizační kódy
        err_auth_code_used: 'common.formErrors.authorizationCodeUsed',
        // err_auth_code_sent_already: '',
        err_auth_code_expired: 'common.formErrors.authorizationCodeExpired',
        err_auth_code_invalid: 'common.formErrors.wrongCode',
        err_auth_code_too_many_attempts: 'common.formErrors.tooManyAttempts',
        // err_auth_code_multiplicity: '',
        // err_auth_code_mismatch: '',
        //
        err_auth_user_or_credentials_invalid: 'common.formErrors.invalidPasswordOrLogin',
        // err_auth_email_invalid: '',
        // err_auth_phone_invalid: '',
        // err_auth_login_name_invalid: '',
        // tokeny
        // err_auth_acc_token_expired: '',
        // err_auth_acc_token_not_found: '',
        // err_auth_acc_token_mismatch: '',
        // err_auth_acc_token_invalid_format: '',
        // err_auth_acc_token_not_valid: '',
        // hesla
        err_auth_pwd_short: 'common.formErrors.invalidPasswordFormatShort',
        err_auth_pwd_miss_upper_lower: 'common.formErrors.invalidPasswordFormatMissUpperLower',
        err_auth_pwd_has_no_digit: 'common.formErrors.invalidPasswordFormatNoDigit',
        err_auth_pwd_not_valid: 'common.formErrors.invalidPasswordFormat',
        err_auth_pwd_history_violation: 'common.formErrors.museBeDifferentFromThreePasswords',
    };
    return translateKeys[errorCode] || defaultTranslate;
};

declare module 'yup' {
    interface StringSchema {
        peselFormat(pesel: string): StringSchema;
        compareBirthDtPesel(birthDate: string): StringSchema;
    }
}

// Special validation method for PESEL
Yup.addMethod(Yup.string, 'peselFormat', function (errorMessage) {
    return this.test(`pesel-format`, errorMessage, function (value) {
        const { path, createError } = this;
        return (value && validatePesel(value)) || createError({ path, message: errorMessage });
    });
});

Yup.addMethod(Yup.string, 'compareBirthDtPesel', function (errorMessage) {
    return this.test(`compare-birthdt-pesel`, errorMessage, function (value) {
        const { path, createError, parent } = this;
        const birthDt = dayjs(parent.birthDate).format(DATE_FORMAT);
        return (
            (value && birthDt && compareBirthDtPesel(birthDt, value.toString())) ||
            createError({ path, message: errorMessage })
        );
    });
});

export const isFinishedLocation = (pathName?: string): boolean => {
    return (
        !pathName ||
        !!(
            matchPath(ROUTE.FINISH, pathName) ||
            matchPath(ROUTE.FINISH_REMOTE, pathName) ||
            matchPath(ROUTE.SUMMARY_FAIL, pathName) ||
            matchPath(ROUTE.AUTOPAY_PAYMENT_METHOD, pathName)
        )
    );
};

export const prevalidateValues = (values: any, schema: Yup.ObjectSchema<any>, defaultValid: boolean = true) => {
    if (values) {
        // Get array of required fields from Yup schema
        const requiredFields = [];
        const schemaFields = schema.describe().fields;
        for (const key in schemaFields) {
            if (Object.prototype.hasOwnProperty.call(schemaFields, key)) {
                const field: any = schemaFields[key];
                !field?.optional && requiredFields.push(key);
            }
        }

        // Loop through required fields and filter empty
        const emptyRequiredFields = requiredFields.filter((rf) => !values[rf] && rf);
        // If some empty required fields, disable Continue
        return emptyRequiredFields.length === 0;
    }

    return defaultValid;
};

export const getDataTest = (props: any, suffix?: string) =>
    'data-test' in props ? `${props['data-test']}${suffix || ''}` : undefined;

export const stringCapitalize = (text: string): string => text.charAt(0).toUpperCase() + text.slice(1);

export const validFileForUpload = (file: File): string | null => {
    if (file.size > config.MAX_UPLOAD_SIZE) {
        return 'common.formErrors.largeFileSize';
    } else if (!isAllowedFileType(file)) {
        return 'common.formErrors.wrongFileFormat';
    } else {
        return null;
    }
};
