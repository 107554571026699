import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';

import { Switch } from '../../components/common/Switch';
import { Card } from '../../components/common/Card';
import { Title } from '../../components/common/Title';
import { Radio } from '../../components/common/Radio';
import { FormError } from '../../components/common/FormError';
import { IdentityConfirmatorType } from '../../types/enums/IdentityConfirmatorType';
import { IdentityConfirmationSourceType } from '../../types/enums/IdentityConfirmationSourceType';
import { useContextHelpers } from '../../utils/hooks';

interface IdentityVerificationFormProps {
    values: any;
}

const IdentityVerificationForm = ({ values }: IdentityVerificationFormProps) => {
    const { t } = useTranslation();
    const { isF2F, isRemote, withoutDocumentPhotos } = useContextHelpers();
    const pageTranslation: any = t('pages.identityVerification', { returnObjects: true });

    let displayOtherCheckboxes = values.declaration || isRemote;
    if (withoutDocumentPhotos) {
        displayOtherCheckboxes = false;
    }

    return (
        <div className="flex flex-col gap-y-10">
            {isF2F && (
                <Card data-test="declarationCard">
                    <Title tag="h2" size="md" fontWeight="medium" className="mb-8" data-test="declarationCardTitle">
                        {pageTranslation.card[0].title}
                    </Title>
                    <Field
                        name="declaration"
                        label={pageTranslation.card[0].declaration.title}
                        component={Switch}
                        data-test="declaration"
                    />
                </Card>
            )}

            {displayOtherCheckboxes && (
                <>
                    <Card data-test="personalDataCard">
                        <Title
                            tag="h2"
                            size="md"
                            fontWeight="medium"
                            className="mb-8"
                            data-test="personalDataCardTitle"
                        >
                            {pageTranslation.card[1].title}
                        </Title>

                        <Field
                            name="personalData"
                            label={pageTranslation.card[1].mojeId.title}
                            value={IdentityConfirmationSourceType.MojeId.toString()}
                            component={Radio}
                            helpText={pageTranslation.card[1].mojeId.tooltip}
                            isDisabled
                            type="radio"
                            className="mb-8"
                            data-test="personalDataMojeId"
                        />
                        <Field
                            name="personalData"
                            label={pageTranslation.card[1].takingSnap.title}
                            value={IdentityConfirmationSourceType.Photocopy.toString()}
                            component={Radio}
                            type="radio"
                            data-test="personalDataPhotocopy"
                        />
                        <FormError name="personalData" className="mt-2" data-test="personalDataError" />
                    </Card>

                    {values.personalData === IdentityConfirmationSourceType.Photocopy.toString() && (
                        <Card data-test="phototocopyCard">
                            <Title tag="h2" size="md" fontWeight="medium" className="mb-8" data-test="phototocopyTitle">
                                {pageTranslation.card[2].title}
                            </Title>

                            <Field
                                name="confirmatorType"
                                label={pageTranslation.card[2].myDevice.title}
                                value={IdentityConfirmatorType.Agent.toString()}
                                component={Radio}
                                type="radio"
                                className="mb-8"
                                data-test="confirmatorTypeAgent"
                            />
                            <Field
                                name="confirmatorType"
                                label={pageTranslation.card[2].clientDevice.title}
                                value={IdentityConfirmatorType.Client.toString()}
                                component={Radio}
                                type="radio"
                                data-test="confirmatorTypeClient"
                            />
                            <FormError name="confirmatorType" className="mt-2" data-test="confirmatorTypeError" />
                        </Card>
                    )}
                </>
            )}
        </div>
    );
};

export default IdentityVerificationForm;
