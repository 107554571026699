import React, { Fragment, ReactNode, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Column, { ColumnProps } from './components/Column';
import Actions from './components/Actions';
import Header from './components/Header';
import Data from './components/Data';
import Toggable from './components/Toggable';
import ToggleArrow from './components/ToggleArrow';
import Pagination from './components/Pagination';
import RecordsOnPage, { RecordsOnPageList } from './components/RecordsOnPage';
import Loader from '../common/Loader';
import { getDataTest } from '../../utils';

export interface RecordsLimits {
    limit: number;
    offset: number;
}

export type SortBy = 'asc' | 'desc';

export interface SortProps {
    sortField: string;
    sortBy: SortBy;
}

export interface FiltersProps {
    [filter: string]: { [parameter: string]: string | number };
}

interface DataTableProps<T> {
    className?: string;
    name?: string;
    page?: number;
    recordsOnPage?: number;
    limit?: RecordsLimits;
    records?: T[];
    recordsCount?: number;
    sort?: SortProps;
    toggableRows?: boolean;
    loading?: boolean;
    isError?: any;
    disablePagination?: boolean;
    disableRecordsOnPage?: boolean;
    isInvalid?: boolean;
    children?: React.ReactNode;
    refetch?: () => void;
    onToggleContent?: (data: T) => ReactNode;
    handlePageChange?: (page: number) => void;
    handleRecordsOnPageChange?: (value: number) => void;
    handleSortChange?: (sort: SortProps) => void;
}

const DefaultLimit: RecordsLimits = { limit: 20, offset: 0 };

const DataTable = <T,>({
    className,
    page = 0,
    recordsOnPage = RecordsOnPageList[0],
    limit = DefaultLimit,
    recordsCount = 0,
    records = [],
    sort,
    toggableRows = false,
    loading = false,
    isError = false,
    disablePagination = false,
    disableRecordsOnPage = false,
    isInvalid,
    refetch,
    onToggleContent,
    handlePageChange,
    handleRecordsOnPageChange,
    handleSortChange,
    children,
    ...props
}: DataTableProps<T>) => {
    const { t } = useTranslation();
    const [dataSourceCached, setDataSourceCached] = useState<T[]>(records);
    // eslint-disable-next-line
    const [recordsCountCached, setRecordsCountCached] = useState(recordsCount);
    const [toggleRow, setToggleRow] = useState<number | null>(null);

    const columns: Array<any> = [];

    if (!Array.isArray(children)) {
        if ((children as any).type === Column || (children as any).type === Actions) {
            columns.push(children);
        }
    }
    if (children instanceof Array) {
        children.forEach((child) => {
            if (child instanceof Array) {
                child.forEach((subChild: any) => {
                    columns.push(subChild);
                });
            } else {
                columns.push(child);
            }
        });
    }

    const onToggleRow = (index: number) => setToggleRow(toggleRow === index ? null : index);

    useEffect(() => {
        if (!loading && !isError) {
            setDataSourceCached(records);
            setRecordsCountCached(recordsCount);
        }
    }, [loading, isError, records, recordsCount]);

    useEffect(() => {
        if (toggleRow !== null) {
            setToggleRow(null);
        }
        // eslint-disable-next-line
    }, [page]);

    const columnsCount = useMemo(() => columns.length + (toggableRows ? 1 : 0), [columns.length, toggableRows]);

    return (
        <div className="overflow-auto">
            <table
                border={0}
                cellSpacing={0}
                cellPadding={0}
                className={cn('styled-table dataTable', className)}
                {...props}
            >
                <thead>
                    <tr>
                        {toggableRows && <Toggable component="th" />}
                        {columns.map((column: React.Component<ColumnProps>, index: number) => {
                            const { props: columnProps } = column;
                            return (
                                <Header
                                    key={index}
                                    column={column}
                                    currentSort={sort}
                                    handleSortChange={handleSortChange}
                                    data-test={getDataTest(props, `Header[${columnProps?.dataIndex || index}]`)}
                                >
                                    {columnProps.label}
                                </Header>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {loading && dataSourceCached.length === 0 && (
                        <tr>
                            <td
                                colSpan={columnsCount}
                                className="py-4 text-center"
                                data-test={getDataTest(props, 'Loader')}
                            >
                                <Loader />
                            </td>
                        </tr>
                    )}
                    {isError && !loading && (
                        <tr>
                            <td
                                colSpan={columnsCount}
                                className="py-4 text-center"
                                data-test={getDataTest(props, 'Error')}
                            >
                                {t('common.error')}
                            </td>
                        </tr>
                    )}
                    {!isError &&
                        dataSourceCached?.map((data: T, index: number) => (
                            <Fragment key={index}>
                                <tr data-test={getDataTest(props, `Row[${index}]`)}>
                                    {toggableRows && (
                                        <Toggable component="td">
                                            <ToggleArrow
                                                down={toggleRow !== index}
                                                onClick={() => onToggleRow(index)}
                                            />
                                        </Toggable>
                                    )}
                                    {columns.map((column: React.Component<ColumnProps>, cIndex: number) => (
                                        <Data
                                            key={cIndex}
                                            column={column}
                                            data={data}
                                            index={index}
                                            data-test={getDataTest(props, `Data[${column.props.dataIndex}]`)}
                                        />
                                    ))}
                                </tr>
                                {toggleRow === index && onToggleContent && (
                                    <tr>
                                        <td
                                            className="toggle-content"
                                            colSpan={columnsCount}
                                            data-test={getDataTest(props, 'ToggleContent')}
                                        >
                                            {onToggleContent(data)}
                                        </td>
                                    </tr>
                                )}
                            </Fragment>
                        ))}
                    {!loading && !isError && dataSourceCached.length === 0 && records.length === 0 && (
                        <tr>
                            <td
                                colSpan={columnsCount}
                                className="py-4"
                                data-test={getDataTest(props, 'NoRecordsFound')}
                            >
                                {t('common.noRecordsFound')}
                            </td>
                        </tr>
                    )}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={columnsCount} data-test={getDataTest(props, 'Footer')}>
                            {recordsCountCached > 0 && (
                                <div data-test="recordsCount">
                                    {t('dashboard.common.recordsCount')}: <strong>{recordsCountCached}</strong>
                                    <br />
                                </div>
                            )}
                            {handleRecordsOnPageChange && recordsCountCached > 0 && !disableRecordsOnPage && (
                                <RecordsOnPage recordsOnPage={recordsOnPage} handleChange={handleRecordsOnPageChange} />
                            )}
                            {handlePageChange && !disablePagination && (
                                <Pagination
                                    page={page}
                                    recordsCount={recordsCountCached}
                                    recordsPerPage={recordsOnPage}
                                    handleChange={handlePageChange}
                                />
                            )}
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default DataTable;
