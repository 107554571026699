import { ChangeEvent, Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FormikValues, Field, Form, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import * as Yup from 'yup';

import { Grid } from '../../components/Grid';
import { Head } from '../../components/Head';
import { Card } from '../../components/common/Card';
import { Input } from '../../components/common/Input';
import { Select } from '../../components/common/Select';
import { Title } from '../../components/common/Title';
import { Switch } from '../../components/common/Switch';
import { CustomFormik } from '../../components/CustomFormik';
import { AppContext } from '../../components/Context';
import { REGEX, ROUTE, STEP_CODE, SUPPORTED_UPLOAD_FILE_TYPES } from '../../constants';
import {
    parseValidationErrors,
    subtractDate,
    getCountriesSelectList,
    getOwner,
    getSelectValueByCode,
    getIdentificatorSelectList,
    getSexSelectList,
    getHigherStep,
    getOwnerParticipant,
    isDevelopment,
    getBase64,
    getBase64FileContent,
    validFileForUpload,
} from '../../utils';
import { callValidateOnlinePolicyPlSanction, callValidatePersonalInformation } from '../../apis/validations';
import { ErrorType, ValidationResultType } from '../../types';
import GlobalError from '../../components/GlobalError';
import { config } from '../../config';
import { ModelProps, ParticipantPersonProps, PersonalIdentificationFullProps } from '../../types/model';
import { IdentificationType } from '../../types/enums/IdentificationType';
import { useAppNavigate, useContextHelpers } from '../../utils/hooks';
import Loader from '../../components/common/Loader';
import { callValidateClientPersonalInformationExist } from '../../apis/validations/validateClientPersonalInformationExist';
import LimitedAccess from '../../components/LimitedAccess';
import { Layout } from '../../components/Layout';
import { DetectFormikChanged } from '../../components/common/DetectFormikChanged';
import DateInput from '../../components/common/DateInput';
import DragAndDropUpload from '../../components/common/DragAndDropUpload';
import UploadedFiles from './UploadedFiles';
import { callAttachDocument, callGetAttachmentMetaData } from '../../apis/documents';

const PersonalData = () => {
    const { t } = useTranslation();
    const [continueDisabled, setContinueDisabled] = useState(false);
    const { initData, ...ctx } = useContext(AppContext);
    const { isPep, isF2F, isRemote } = useContextHelpers();
    const { navigateTo } = useAppNavigate();
    const [errors, setErrors] = useState<ErrorType[]>([]);
    const [pepFiles, setPepFiles] = useState<any[]>([]);
    const [errorSanctionRegion, setErrorSanctionRegion] = useState(false);
    const data = ctx.currentModel;
    const ownerId = data ? getOwner(data)?.ParticipantExternalId : null;
    const idList = getIdentificatorSelectList(initData);
    const sexList = getSexSelectList(initData);
    const ownerParticipant =
        data && ownerId
            ? (getOwnerParticipant(data, ownerId) as ParticipantPersonProps<PersonalIdentificationFullProps>) ?? null
            : null;
    const otherParticipants = data?.Participants.filter((p) => p && p.ExternalId !== ownerId);
    const countriesSelectData = getCountriesSelectList(initData) || [];
    const myFormikRef = useRef<FormikProps<any>>(null);
    const token = localStorage.getItem('token');

    // @TODO: We will get them from API
    const idDetail = ownerParticipant?.Identification?.PersonalIdentificationDetail;
    const permanentAddress = ownerParticipant?.Identification?.PermanentAddress;
    const contactAddress = ownerParticipant?.Identification?.ContactAddress;

    const [hasAnotherAddress, setHasAnotherAddress] = useState(!!contactAddress?.CityName ?? false);

    const initialValues = {
        name: idDetail?.FirstName ?? (isDevelopment() ? 'Anna' : ''),
        surname: idDetail?.LastName ?? (isDevelopment() ? 'Nowak' : ''),
        identificatorNumber: idDetail?.PersonalIdentifier ?? (isDevelopment() ? '74060368943' : ''),
        clientIsPep: ownerParticipant?.IsPep ?? false,
        identificatorType: getSelectValueByCode(idList, IdentificationType.Pesel),
        sex: getSelectValueByCode(sexList, ownerParticipant?.Identification?.Sex) ?? null,
        // @TODO: BirthDt again?
        birthDate: ownerParticipant?.Identification?.BirthDt,
        birthState: getSelectValueByCode(countriesSelectData, idDetail?.BirthCountryCode) ?? countriesSelectData?.[0],
        birthCity: idDetail?.Birthplace ?? (isDevelopment() ? 'Warsaw' : ''),
        citizenship: getSelectValueByCode(countriesSelectData, idDetail?.CitizenshipCode) ?? countriesSelectData?.[0],
        secondCitizenship: getSelectValueByCode(countriesSelectData, idDetail?.SecondCitizenshipCode) ?? null,
        city: permanentAddress?.CityName ?? (isDevelopment() ? 'Warsawa' : ''),
        street: permanentAddress?.StreetName ?? (isDevelopment() ? 'Default' : ''),
        houseNumber: permanentAddress?.HouseNo ?? (isDevelopment() ? '1' : ''),
        postalCode: permanentAddress?.Zip ?? (isDevelopment() ? '00-001' : ''),
        country: getSelectValueByCode(countriesSelectData, permanentAddress?.CountryCode) ?? countriesSelectData?.[0],
        contactStreet: contactAddress?.StreetName ?? '',
        contactHouseNumber: contactAddress?.HouseNo ?? '',
        contactCity: contactAddress?.CityName ?? '',
        contactPostalCode: contactAddress?.Zip ?? '-',
        contactCountry:
            getSelectValueByCode(countriesSelectData, contactAddress?.CountryCode) ?? countriesSelectData?.[0],
        accountNumber: ownerParticipant?.Identification?.BankAccount?.Iban
            ? ownerParticipant?.Identification?.BankAccount?.Iban.replace(new RegExp('^PL'), '').replaceAll(' ', '')
            : '',
        contactAddressDifferent: hasAnotherAddress,
    };

    const formSchema: any = Yup.object().shape({
        name: Yup.string().required().min(2),
        surname: Yup.string().required().min(2),
        identificatorNumber: Yup.string()
            .peselFormat(t('common.formErrors.wrongPesel'))
            .compareBirthDtPesel(t('common.formErrors.peselDontMatchBirthDt'))
            .required(),
        identificatorType: Yup.object().required(),
        sex: Yup.object().required(),
        birthDate: Yup.date()
            .required()
            .min(subtractDate(config.MAX_AGE, 'years'))
            .max(subtractDate(config.MIN_AGE, 'years')),
        birthState: Yup.object().required(),
        birthCity: Yup.string().required().min(2),
        citizenship: Yup.object().required(),
        secondCitizenship: Yup.object().nullable(),
        city: Yup.string().required().min(2),
        street: Yup.string().required(),
        houseNumber: Yup.string().required(),
        postalCode: Yup.string()
            .required()
            .matches(new RegExp(REGEX.POLISH_POST_REGEX), t('common.formErrors.wrongPostalCode') || ''),
        country: Yup.object().required(),
        contactStreet: hasAnotherAddress ? Yup.string().required() : Yup.string(),
        contactHouseNumber: hasAnotherAddress ? Yup.string().required() : Yup.string(),
        contactCity: hasAnotherAddress ? Yup.string().required().min(2) : Yup.string().min(2),
        contactPostalCode: hasAnotherAddress
            ? Yup.string()
                  .required()
                  .matches(new RegExp(REGEX.POLISH_POST_REGEX), t('common.formErrors.wrongPostalCode') || '')
            : Yup.string(),
        contactCountry: hasAnotherAddress ? Yup.object().required() : Yup.object(),
        accountNumber: Yup.string().matches(
            new RegExp(REGEX.POLISH_BANK_NUMBER),
            t('common.formErrors.wrongBankAccountNumber') || ''
        ),
    });

    const updateData = (v: FormikValues, withConfirmationStep: boolean = false): ModelProps => {
        let newModel = { ...data } as ModelProps;

        if (ownerParticipant && v && data) {
            // Identification
            ownerParticipant.Identification = {
                ...ownerParticipant.Identification,
                Sex: v?.sex?.value ?? ownerParticipant.Identification.Sex,
            };

            // Personal information
            ownerParticipant.Identification.PersonalIdentificationDetail = {
                ...ownerParticipant.Identification.PersonalIdentificationDetail,
                BirthCountryCode: v?.birthState?.value ?? null,
                Birthplace: v?.birthCity,
                CitizenshipCode: v?.citizenship?.value ?? null,
                FirstName: v?.name,
                LastName: v?.surname,
                PersonalIdentifier: v?.identificatorNumber,
                PersonalIdentifierType: v?.identificatorType?.value ?? null,
                SecondCitizenshipCode: v?.secondCitizenship?.value ?? null,
                TitleAfter: '',
                TitleBefore: '',
            };

            // Registered address
            ownerParticipant.Identification.PermanentAddress = {
                ...ownerParticipant.Identification.PermanentAddress,
                CityName: v?.city,
                CountryCode: v?.country?.value ?? null,
                HouseNo: v?.houseNumber,
                StreetName: v?.street,
                Zip: v?.postalCode,
            };

            // Contact address
            if (hasAnotherAddress) {
                if (
                    v?.contactCity ||
                    v?.contactCountry?.value ||
                    v?.contactHouseNumber ||
                    v?.contactStreet ||
                    v?.contactPostalCode
                ) {
                    ownerParticipant.Identification.ContactAddress = {
                        ...ownerParticipant.Identification.ContactAddress,
                        CityName: v?.contactCity ?? '',
                        CountryCode: v?.contactCountry?.value ?? '',
                        HouseNo: v?.contactHouseNumber ?? '',
                        StreetName: v?.contactStreet ?? '',
                        Zip: v?.contactPostalCode ?? '',
                    };
                } else {
                    ownerParticipant.Identification.ContactAddress = null;
                }
            } else {
                ownerParticipant.Identification.ContactAddress = null;
            }

            // Bank account
            if (v?.accountNumber) {
                ownerParticipant.Identification.BankAccount = {
                    ...ownerParticipant.Identification.BankAccount,
                    Iban: v?.accountNumber,
                    Code: '',
                    BankAccountNo: '',
                    Prefix: '',
                };
            } else {
                ownerParticipant.Identification.BankAccount = null;
            }
            ownerParticipant.IsPep = v?.clientIsPep;

            newModel = {
                ...data,
                Participants: [ownerParticipant, ...(otherParticipants ?? [])],
            };

            if (withConfirmationStep) {
                newModel = {
                    ...data,
                    Settings: {
                        ...data.Settings,
                        CurrentStepCode: getHigherStep(data.Settings?.CurrentStepCode, STEP_CODE.PERSONAL_DATA),
                    },
                };
            }

            ctx.setCurrentModel(newModel);
        }

        return newModel;
    };

    const onChangeRegion = () => {
        if (errorSanctionRegion) {
            setErrorSanctionRegion(false);
            setContinueDisabled(false);
        }
    };

    const handleUploadPepFiles = (files: FileList | null) => {
        if (files) {
            setPepFiles((prev) => [...prev, ...Array.from(files)]);
        }
    };

    const handleDeleteFile = (_: File, index: number) => {
        setPepFiles((prev) => [...prev].filter((_, i) => i !== index));
    };

    const uploadPepFiles = async () => {
        if (data && token) {
            const ret = await pepFiles.reduce(async (promise, file) => {
                const accumulator = await promise;
                if (file instanceof File) {
                    const fileContent = file && (await getBase64(file));
                    const result = await callAttachDocument({
                        data: {
                            onlinePolicyExternalId: data.ExternalId,
                            attachmentData: getBase64FileContent(fileContent),
                            externalId: uuid(),
                            fileName: file?.name,
                            pageNumber: 1,
                            onlinePolicyAttachmentTypeCode: 'PepQuestionnaire',
                        },
                        token,
                    });
                    accumulator.push(result);
                }
                return accumulator;
            }, Promise.resolve([]));

            return ret;
        }
    };

    const isPepFilesValid = useMemo(
        () =>
            !isPep ||
            isRemote ||
            (isPep &&
                pepFiles.length > 0 &&
                pepFiles.filter((file) => (file instanceof File ? validFileForUpload(file) !== null : false)).length ===
                    0),
        [isPep, isRemote, pepFiles]
    );

    useEffect(() => {
        if (token && data) {
            callGetAttachmentMetaData({ token, data: { onlinePolicyExternalId: data.ExternalId } })
                .then((response) => response.data)
                .then((result) => {
                    if (result?.DocumentMetadataList) {
                        setPepFiles(
                            result.DocumentMetadataList.filter(
                                (attachment) => attachment.OnlinePolicyAttachmentTypeCode === 'PepQuestionnaire'
                            )
                        );
                    }
                });
        }
        // eslint-disable-next-line
    }, [token]);

    return (
        <Layout
            continueDisabled={continueDisabled || !isPepFilesValid}
            continueTooltip={errorSanctionRegion && t('pages.personalContactInformation.tooltipIsFromSanctionRegion')}
        >
            {data ? (
                <LimitedAccess minStep={STEP_CODE.ID_DETAILS}>
                    <Head
                        heading1={t('pages.personalContactInformation.title')}
                        heading2={t('pages.personalContactInformation.subtitle')}
                    />

                    {errors && (
                        <GlobalError
                            errors={errors}
                            withoutTitle={errorSanctionRegion && errors.length === 1}
                            data-test="error"
                        />
                    )}

                    <CustomFormik
                        initialValues={{
                            ...initialValues,
                        }}
                        customRender
                        onSubmit={async (v: any) => {
                            const newModel = updateData(v, true);
                            setErrorSanctionRegion(false);
                            setErrors([]);

                            if (v.clientIsPep && isRemote) {
                                ctx.showPopup('pep');
                            } else {
                                ctx.showPopup(null);
                                if (token) {
                                    setContinueDisabled(true);

                                    // z ibanu odstraním PL
                                    if (v?.accountNumber && newModel && ownerId) {
                                        const ownerParticipant =
                                            newModel && ownerId
                                                ? (getOwnerParticipant(
                                                      newModel,
                                                      ownerId
                                                  ) as ParticipantPersonProps<PersonalIdentificationFullProps>) ?? null
                                                : null;
                                        if (ownerParticipant) {
                                            ownerParticipant.Identification.BankAccount = {
                                                ...ownerParticipant.Identification.BankAccount,
                                                Iban: ownerParticipant.Identification.BankAccount?.Iban
                                                    ? `PL${ownerParticipant.Identification.BankAccount?.Iban?.toUpperCase()
                                                          .replaceAll(' ', '')
                                                          .replace(new RegExp('^PL'), '')
                                                          .substring(0, 26)}`
                                                    : '',
                                                Code: '',
                                                BankAccountNo: '',
                                                Prefix: '',
                                            };
                                        }
                                    }

                                    let sanction = null;
                                    try {
                                        sanction = await callValidateOnlinePolicyPlSanction({
                                            data: {
                                                onlinePolicyPlJson: JSON.stringify(newModel),
                                            },
                                            token,
                                        });
                                    } catch {}

                                    // je v sankčním seznamu
                                    if (!sanction || sanction?.data?.IsValid !== true) {
                                        setErrorSanctionRegion(true);
                                        setErrors([
                                            {
                                                code: '',
                                                message: t(
                                                    'pages.personalContactInformation.errors.isFromSanctionRegion'
                                                ),
                                            },
                                        ]);
                                        setContinueDisabled(true);
                                    }

                                    // není v sankčním seznamu
                                    if (sanction && sanction?.data?.IsValid === true) {
                                        // nahraje pep soubory
                                        await uploadPepFiles();

                                        // zvaliduje osobní údaje
                                        callValidatePersonalInformation({ data: newModel, token })
                                            .then((response) => response.data)
                                            .then((result: ValidationResultType) => {
                                                ctx.saveCurrentModel(data);
                                                if (result.Passed) {
                                                    callValidateClientPersonalInformationExist({
                                                        data: {
                                                            PersonFirstName: v.name,
                                                            PersonLastName: v.surname,
                                                            PersonEmail:
                                                                newModel?.Participants?.[0].Contact?.Email ?? '',
                                                            PersonPhone:
                                                                newModel?.Participants?.[0].Contact?.Phone ?? '',
                                                            PersonPhonePrefix:
                                                                newModel.Participants?.[0].Contact?.PhonePrefix
                                                                    .Prefix ?? '',
                                                            PersonPesel: v.identificatorNumber,
                                                        },
                                                        token,
                                                    })
                                                        .then(
                                                            (responsePersonalInformation) =>
                                                                responsePersonalInformation.data
                                                        )
                                                        .then((resultPersonalInformation) => {
                                                            if (
                                                                resultPersonalInformation.IsValidByEmailAndPhone &&
                                                                resultPersonalInformation.IsValidByPesel
                                                            ) {
                                                                navigateTo(ROUTE.BENEFICIARIES);
                                                            } else {
                                                                const error: ErrorType[] = [];
                                                                if (
                                                                    resultPersonalInformation.IsValidByEmailAndPhone ===
                                                                        undefined ||
                                                                    !resultPersonalInformation.IsValidByEmailAndPhone
                                                                ) {
                                                                    error.push({
                                                                        code: '',
                                                                        message: t(
                                                                            'pages.personalContactInformation.errors.isValidByEmailAndPhone'
                                                                        ),
                                                                    });
                                                                }
                                                                if (
                                                                    resultPersonalInformation.IsValidByPesel ===
                                                                        undefined ||
                                                                    !resultPersonalInformation.IsValidByPesel
                                                                ) {
                                                                    error.push({
                                                                        code: '',
                                                                        message: t(
                                                                            'pages.personalContactInformation.errors.isValidByPesel'
                                                                        ),
                                                                    });
                                                                }
                                                                setErrors(error);
                                                                if (isDevelopment()) {
                                                                    setTimeout(
                                                                        () => navigateTo(ROUTE.BENEFICIARIES),
                                                                        10000
                                                                    );
                                                                }
                                                            }
                                                        });
                                                }
                                                if (!result.Passed) {
                                                    setErrors(parseValidationErrors(result));
                                                    if (isDevelopment()) {
                                                        setTimeout(() => navigateTo(ROUTE.BENEFICIARIES), 10000);
                                                    }
                                                }
                                            })
                                            .finally(() => setContinueDisabled(false));
                                    }
                                }
                            }
                        }}
                        validationSchema={formSchema}
                        passedRef={myFormikRef}
                    >
                        {({ values }: FormikProps<any>) => (
                            <Form noValidate>
                                <DetectFormikChanged onChange={updateData} />
                                <Card className="gap-y-12" data-test="personalDataCard">
                                    <div className="flex flex-col justify-center">
                                        <Title
                                            tag="h3"
                                            size="md"
                                            fontWeight="medium"
                                            className="mb-6"
                                            data-test="personalDataTitle"
                                        >
                                            {t('pages.personalContactInformation.card.titles.personalInformation')}
                                        </Title>
                                        <Grid>
                                            <Field
                                                name="name"
                                                isCenter
                                                label={t('common.formFields.name')}
                                                component={Input}
                                                data-test="name"
                                            />
                                            <Field
                                                name="surname"
                                                isCenter
                                                label={t('common.formFields.surname')}
                                                component={Input}
                                                data-test="surname"
                                            />
                                            <Field
                                                component={Select}
                                                name="identificatorType"
                                                label={t('common.formFields.identificatorType')}
                                                isCenter
                                                isSearchable={false}
                                                options={idList}
                                                isDisabled
                                                data-test="identificatorType"
                                            />
                                            <Field
                                                name="identificatorNumber"
                                                isCenter
                                                label={t('common.formFields.identificatorNumber')}
                                                component={Input}
                                                maxLength={50}
                                                isStringNumber
                                                data-test="identificatorNumber"
                                            />
                                            <Field
                                                component={Select}
                                                name="sex"
                                                label={t('common.formFields.sex')}
                                                isCenter
                                                options={sexList}
                                                data-test="sex"
                                            />
                                            <Field
                                                isDisabled
                                                name="birthDate"
                                                isCenter
                                                label={t('common.formFields.birthDate')}
                                                component={DateInput}
                                                minDate={new Date(subtractDate(config.MAX_AGE, 'years'))}
                                                maxDate={new Date(subtractDate(config.MIN_AGE, 'years'))}
                                                data-test="birthDate"
                                            />
                                            <Field
                                                component={Select}
                                                name="birthState"
                                                label={t('common.formFields.birthState')}
                                                isCenter
                                                options={countriesSelectData}
                                                isSearchable
                                                data-test="birthState"
                                            />
                                            <Field
                                                name="birthCity"
                                                isCenter
                                                label={t('common.formFields.birthCity')}
                                                component={Input}
                                                data-test="birthCity"
                                            />
                                            <Field
                                                name="citizenship"
                                                isCenter
                                                options={countriesSelectData}
                                                label={t('common.formFields.citizenship')}
                                                component={Select}
                                                isSearchable
                                                handleChange={onChangeRegion}
                                                data-test="citizenship"
                                            />
                                            <Field
                                                name="secondCitizenship"
                                                isCenter
                                                options={countriesSelectData}
                                                label={t('common.formFields.secondCitizenship')}
                                                component={Select}
                                                isSearchable
                                                isClearable
                                                handleChange={onChangeRegion}
                                                data-test="secondCitizenship"
                                            />
                                        </Grid>
                                    </div>

                                    <div className="flex flex-col justify-center border-b-1 border-purple pb-12">
                                        <Title
                                            tag="h3"
                                            size="md"
                                            fontWeight="medium"
                                            className="mb-6"
                                            data-test="addressTitle"
                                        >
                                            {t('pages.personalContactInformation.card.titles.registeredAddress')}
                                        </Title>
                                        <Grid className="mb-12">
                                            <Field
                                                name="street"
                                                isCenter
                                                label={t('common.formFields.street')}
                                                component={Input}
                                                data-test="street"
                                            />
                                            <Field
                                                name="houseNumber"
                                                isCenter
                                                label={t('common.formFields.houseNumber')}
                                                component={Input}
                                                maxLength={50}
                                                data-test="houseNumber"
                                            />
                                        </Grid>
                                        <Grid cols={3} className="mb-12">
                                            <Field
                                                name="city"
                                                isCenter
                                                label={t('common.formFields.city')}
                                                component={Input}
                                                data-test="city"
                                            />
                                            <Field
                                                name="postalCode"
                                                isCenter
                                                label={t('common.formFields.postalCode')}
                                                component={Input}
                                                maxLength={10}
                                                data-test="postalCode"
                                            />
                                            <Field
                                                name="country"
                                                component={Select}
                                                label={t('common.formFields.country')}
                                                isCenter
                                                options={countriesSelectData}
                                                isSearchable
                                                handleChange={onChangeRegion}
                                                data-test="country"
                                            />
                                        </Grid>

                                        <Field
                                            component={Switch}
                                            label={t('common.formFields.contacAddressDifferent')}
                                            name="contacAddressDifferent"
                                            checked={hasAnotherAddress}
                                            className="mx-auto"
                                            data-test="contacAddressDifferent"
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                setHasAnotherAddress(e.target.checked);
                                            }}
                                        />

                                        {hasAnotherAddress && (
                                            <>
                                                <Title
                                                    tag="h3"
                                                    size="md"
                                                    fontWeight="medium"
                                                    className="mb-6 mt-12"
                                                    data-test="contactAddressTitle"
                                                >
                                                    {t('pages.personalContactInformation.card.titles.contactAddress')}
                                                </Title>
                                                <Grid className="mb-12">
                                                    <Field
                                                        name="contactStreet"
                                                        isCenter
                                                        label={t('common.formFields.street')}
                                                        component={Input}
                                                        data-test="contactStreet"
                                                    />
                                                    <Field
                                                        name="contactHouseNumber"
                                                        isCenter
                                                        label={t('common.formFields.houseNumber')}
                                                        component={Input}
                                                        data-test="contactHouseNumber"
                                                    />
                                                </Grid>
                                                <Grid cols={3} className="mb-12">
                                                    <Field
                                                        name="contactCity"
                                                        isCenter
                                                        label={t('common.formFields.city')}
                                                        component={Input}
                                                        data-test="contactCity"
                                                    />
                                                    <Field
                                                        name="contactPostalCode"
                                                        isCenter
                                                        label={t('common.formFields.postalCode')}
                                                        component={Input}
                                                        maxLength={10}
                                                        data-test="contactPostalCode"
                                                    />
                                                    <Field
                                                        name="contactCountry"
                                                        component={Select}
                                                        label={t('common.formFields.country')}
                                                        isCenter
                                                        options={countriesSelectData}
                                                        isSearchable
                                                        data-test="contactCountry"
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                    </div>

                                    <div className="flex flex-col justify-center">
                                        <Title
                                            tag="h3"
                                            size="md"
                                            fontWeight="medium"
                                            className="mb-6"
                                            data-test="bankAccountTitle"
                                        >
                                            {t('pages.personalContactInformation.card.titles.bankAccount')}
                                        </Title>
                                        <Field
                                            component={Input}
                                            name="accountNumber"
                                            label={t('common.formFields.bankAccountNumber')}
                                            className="mb-12"
                                            isCenter
                                            maxLength={36}
                                            data-test="accountNumber"
                                        />
                                        <Field
                                            component={Switch}
                                            label={t('common.formFields.clientIsPep')}
                                            name="clientIsPep"
                                            className="mx-auto"
                                            helpText={t('pages.personalContactInformation.pepHelperText')}
                                            data-test="clientIsPep"
                                        />
                                        {values.clientIsPep && isF2F && (
                                            <Fragment>
                                                <Title
                                                    tag="h3"
                                                    size="md"
                                                    fontWeight="medium"
                                                    className="mt-12"
                                                    data-test="pepQuestionnaireTitle"
                                                >
                                                    {t('pages.personalContactInformation.pepQuestionnaire')}
                                                </Title>
                                                <DragAndDropUpload
                                                    labelUpload={t<string>(
                                                        'pages.personalContactInformation.dragAndDropQuestionnaire'
                                                    )}
                                                    multiple
                                                    accept={SUPPORTED_UPLOAD_FILE_TYPES}
                                                    onUpload={handleUploadPepFiles}
                                                    data-test="pepFilesUpload"
                                                />
                                                <UploadedFiles files={pepFiles} onDelete={handleDeleteFile} />
                                            </Fragment>
                                        )}
                                    </div>
                                </Card>
                            </Form>
                        )}
                    </CustomFormik>
                </LimitedAccess>
            ) : (
                <Loader />
            )}
        </Layout>
    );
};

export { PersonalData };
