import React, { useCallback, useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { callInitData } from '../apis/initialization';
import { AppContext } from './Context';
import { config } from '../config';
import Loader from './common/Loader';

let timer: any;

const CheckInitData = () => {
    const [loading, setLoading] = useState(false);
    const { initData, setInitData, initDataFetchDate, setInitDataFetchDate, userData } = useContext(AppContext);

    const { language } = userData || {};

    const [initDataLanguage, setInitDataLanguage] = useState(language || 'pl');

    const loadInitData = useCallback(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setLoading(true);
            setInitDataLanguage(language || 'pl');
            callInitData({ languageCode: language || 'pl', token })
                .then((result) => result.data)
                .then((data) => {
                    setInitData(data);
                    setInitDataFetchDate(dayjs());
                })
                .catch((err) => console.error(err))
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [language, setInitData, setInitDataFetchDate]);

    const checkInitData = useCallback(() => {
        if (!loading) {
            const hasInit = !!initData;
            const initFetched = initDataFetchDate ? dayjs(initDataFetchDate) : null;
            const initDataAge = initFetched
                ? dayjs().diff(dayjs(initFetched), 'hours')
                : config.MAX_INIT_DATA_AGE_HOURS;

            // Fetch fresh initData every 24 hours max
            if (!hasInit || initDataAge >= config.MAX_INIT_DATA_AGE_HOURS) {
                loadInitData();
            }
        }
    }, [loading, initData, initDataFetchDate, loadInitData]);

    useEffect(() => {
        if (userData) {
            timer = setInterval(() => checkInitData(), 60 * 1000);
        }
        return () => {
            clearInterval(timer);
        };
    }, [userData, checkInitData]);

    useEffect(() => {
        if (language !== initDataLanguage) {
            loadInitData();
        }
    }, [language, initDataLanguage, loadInitData]);

    useEffect(() => {
        if (!initData) {
            loadInitData();
        }
    }, [initData, loadInitData]);

    if (loading) {
        return <Loader overflow />;
    }

    return null;
};

export default CheckInitData;
