import { MutableRefObject, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { FormikProps, FormikValues } from 'formik';

import { AppContext } from '../components/Context';
import { SortProps } from '../components/DataTable/DataTable';
import { DashboardBaseList } from '../pages/consultant/Dashboard/components/Context';
import { getOwner, getOwnerParticipant, getStepPosition, getStepPositionByPathName, isFinishedLocation } from '.';
import { ROUTE, STEP_CODE } from '../constants';
import { RouteProps, routes } from '../routes';
import { config } from '../config';
import { callLogoutAgent } from '../apis/authentications';
import { ParticipantPersonProps, PersonalIdentificationFullProps } from '../types/model';
import { ArrangementKind } from '../types/enums/ArrangementKind';

// Sets current Formik form as current to be able to submit it through footer submit button
export const useSetCurrentFormik = (myFormRef: MutableRefObject<FormikProps<FormikValues> | null>) => {
    const ctx = useContext(AppContext);

    useEffect(() => {
        if (myFormRef?.current) {
            ctx.setCurrentFormik(myFormRef.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myFormRef]);
};

// Returns string with decreasing seconds value
export const useCountdown = (seconds: number) => {
    const [time, setTime] = useState(seconds);
    useEffect(() => {
        const interval = setTimeout(() => {
            if (time < 1) {
                clearTimeout(interval);
            } else {
                setTime(time - 1);
            }
        }, 1000);

        return () => {
            clearTimeout(interval);
        };
    }, [time]);

    return time;
};

// Detects click outside a component
export const useOutsideClickChecker = (
    ref: MutableRefObject<HTMLElement | null>,
    callback: () => void,
    secondDependecies?: any[]
) => {
    useEffect(() => {
        if (ref) {
            const handleClickOutside = (event: Event) => {
                if (ref.current && !ref.current.contains(event.target as Node)) {
                    callback();
                }
            };

            document.addEventListener('mousedown', handleClickOutside);
            return () => document.removeEventListener('mousedown', handleClickOutside);
        }
        // eslint-disable-next-line
    }, [ref, ...(secondDependecies ?? [])]);
};

// Hook for manipulate with tables
interface UseTableContextOutput<T> {
    sort?: SortProps;
    page: number;
    recordsOnPage: number;
    // limits: RecordsLimits;
    fullText: string;
    // requestProps: any;
    loading: boolean;
    isInvalid: boolean;
    isError: boolean;
    records: T[];
    recordsCount: number;
    handleSortChange: (sort: SortProps) => void;
    handleRecordsOnPageChange: (value: number) => void;
    handlePageChange: (page: number) => void;
    handleFullTextSearchChange: (value: string) => void;
    refetch: () => void;
}

interface TableContextFilterOptions<T> {
    simpleFilterContent?: (data: T) => object | string;
    completeFilter?: (data: T) => boolean;
}

export const useTableContext = <T>(
    contextObject: DashboardBaseList<T>,
    contextObjectChange: React.Dispatch<React.SetStateAction<DashboardBaseList<T>>>,
    contextBackendCallback: (props: any) => Promise<AxiosResponse<T[], any>>,
    filter?: any,
    filterOptions?: TableContextFilterOptions<T>
): UseTableContextOutput<T> => {
    const token = localStorage.getItem('token');
    const { simpleFilterContent, completeFilter } = filterOptions || {};

    const { sort, page, recordsOnPage, fullText, loading, isInvalid, isError, records } =
        contextObject as DashboardBaseList<T>;

    const limits = useMemo(
        () => ({
            limit: recordsOnPage,
            offset: page * recordsOnPage > 0 ? page * recordsOnPage : 0,
        }),
        [page, recordsOnPage]
    );

    const requestProps = {
        // sort,
        // limits,
    };

    const refetch = () => {
        contextObjectChange((prev) => ({ ...prev, loading: true, isError: false, isInvalid: false }));
        contextBackendCallback({ data: requestProps, token: token || '' })
            .then((response) => response.data)
            .then((result) => contextObjectChange((prev) => ({ ...prev, records: [...result] })))
            .catch(() => contextObjectChange((prev) => ({ ...prev, isError: true })))
            .finally(() => contextObjectChange((prev) => ({ ...prev, loading: false })));
    };

    // useEffect(() => {
    //     if (isInvalid) {
    //         refetch();
    //     }
    //     // eslint-disable-next-line
    // }, [isInvalid]);

    useEffect(() => {
        refetch();
        // eslint-disable-next-line
    }, [isInvalid]);

    const filteredRecords = useMemo(
        () =>
            records
                .filter((r) =>
                    fullText && simpleFilterContent ? checkFullTextSearch(simpleFilterContent(r), fullText) : true
                )
                .filter((r) => (completeFilter ? completeFilter(r) : true)),
        [records, fullText, simpleFilterContent, completeFilter]
    );

    const displayRecords = useMemo(
        () => filteredRecords.filter((r, index) => index >= limits.offset && index < limits.offset + limits.limit),
        [filteredRecords, limits]
    );

    useEffect(() => {
        if (page > 0) {
            contextObjectChange((prev) => ({ ...prev, page: 0 }));
        }
        // eslint-disable-next-line
    }, [filter]);

    return {
        sort,
        page,
        // limits,
        recordsOnPage,
        fullText,
        // requestProps,
        loading,
        isInvalid,
        isError,
        records: displayRecords,
        recordsCount: filteredRecords.length,
        handleSortChange: (value: SortProps) =>
            contextObjectChange((prev) => ({
                ...prev,
                sort: value,
                // isInvalid: true,
            })),
        handleRecordsOnPageChange: (value: number) =>
            contextObjectChange((prev) => ({
                ...prev,
                recordsOnPage: value,
                page: 0,
                // isInvalid: true,
            })),
        handlePageChange: (value: number) =>
            contextObjectChange((prev) => ({
                ...prev,
                page: value,
                //isInvalid: true
            })),
        handleFullTextSearchChange: (value: string) =>
            contextObjectChange((prev) => ({ ...prev, fullText: value, page: 0 })),
        refetch,
    };
};

interface AppNavigateToOptionsType {
    externalId?: string;
    queryParameters?: any;
    ignoreLimits?: boolean;
}

const initialNavigateToOtion: AppNavigateToOptionsType = {
    externalId: undefined,
    queryParameters: {},
    ignoreLimits: false,
};

export const useAppNavigate = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { summaryErrors, ...ctx } = useContext(AppContext);
    const data = ctx.currentModel;
    const maxStepCode = data?.Settings?.CurrentStepCode;

    const navigateTo = (route: string, options: AppNavigateToOptionsType = initialNavigateToOtion) => {
        const { externalId, queryParameters, ignoreLimits } = options;

        // směruje na korektní step
        const routeStepCode = routes.find((r) => matchPath(r.path, route))?.code;

        // blokace přechodu
        let disableNavigate = false;

        // podmínky pro omezení pohybu
        if (maxStepCode && routeStepCode) {
            // aktuální routa
            const currentRoute = routes.find((r) => matchPath(r.path, location.pathname));

            // jsem na stránce basic info, znemožním možnost jít dál
            if (
                !ignoreLimits &&
                !disableNavigate &&
                currentRoute?.code &&
                getStepPosition(currentRoute?.code) === getStepPosition(STEP_CODE.BASIC_DATA)
            ) {
                // chci přístup do vyššího kroku
                if (getStepPosition(routeStepCode) > getStepPosition(STEP_CODE.BASIC_DATA)) {
                    disableNavigate = true;
                    ctx.showPopup('limited-access-from-basic-info');
                }
            }

            const finished = isFinishedLocation(currentRoute?.path);

            // smlouva se dostala za stav summary včetně
            if (!disableNavigate && getStepPosition(maxStepCode) > getStepPosition(STEP_CODE.SUMMARY)) {
                // chci přístup do nižšího kroku
                // a na summary není žádná chyba
                if (
                    getStepPosition(routeStepCode) <= getStepPosition(STEP_CODE.SUMMARY) &&
                    summaryErrors.length === 0
                ) {
                    // kromě vstupu na mainmenu
                    if (
                        getStepPosition(routeStepCode) !== getStepPosition(STEP_CODE.MAIN_MENU) &&
                        getStepPosition(routeStepCode) !== getStepPosition(STEP_CODE.CONCLUSION)
                    ) {
                        disableNavigate = true;
                        ctx.showPopup('limited-access-to-public', () => navigate(ROUTE.MAIN_MENU));
                    }
                }
            }

            // smlouva se dostala za stránku conclusion
            // a už je nějaký balíček vybrán
            if (
                !ignoreLimits &&
                !disableNavigate &&
                getStepPosition(maxStepCode) >= getStepPosition(STEP_CODE.CONCLUSION) &&
                data?.InsuredPersons?.[0]?.InsurancePackage?.ChosenPackageType
            ) {
                // chci přístup na stránku conclusion nebo package-choise
                if (
                    (getStepPosition(routeStepCode) === getStepPosition(STEP_CODE.CONCLUSION) ||
                        getStepPosition(routeStepCode) === getStepPosition(STEP_CODE.PACKAGE_CHOICE) ||
                        getStepPosition(routeStepCode) === getStepPosition(STEP_CODE.QUESTIONNAIRE)) &&
                    !finished
                ) {
                    disableNavigate = true;
                    ctx.showPopup('limited-access-to-public', () => navigate(ROUTE.CONCLUSION));
                }
                // chci přístup na stránku mainmenu
                else if (getStepPosition(routeStepCode) <= getStepPosition(STEP_CODE.PACKAGE_CHOICE) && !finished) {
                    disableNavigate = true;
                    ctx.showPopup('limited-access-to-public', () => navigate(ROUTE.MAIN_MENU));
                }
            }
        }

        // omezení přístupu do "veřejných" stránek
        if (!disableNavigate) {
            // smlouva se dostala za stránku conclusion
            // a už je nějaký balíček vybrán
            if (
                !ignoreLimits &&
                !disableNavigate &&
                maxStepCode &&
                getStepPosition(maxStepCode) >= getStepPosition(STEP_CODE.CONCLUSION) &&
                data?.InsuredPersons?.[0]?.InsurancePackage?.ChosenPackageType
            ) {
                // uživatel chce jít na stránku změny hesla
                if (matchPath(route, ROUTE.CHANGE_PASSWORD)) {
                    disableNavigate = true;
                    ctx.showPopup('limited-access-to-public', () => navigate(ROUTE.CHANGE_PASSWORD));
                }
            }
        }

        // možno pokračovat
        if (!disableNavigate) {
            const searchParams = new URLSearchParams();
            // TODO: zkusit odstranit config.PATHS_WITHOUT_POLICY
            if (!config.PATHS_WITHOUT_POLICY.includes(route)) {
                if (data?.ExternalId) {
                    searchParams.append('id', data?.ExternalId);
                } else if (externalId) {
                    searchParams.append('id', externalId);
                }
            }
            Object.keys(queryParameters || {}).forEach((key: string) => {
                searchParams.append(key, queryParameters[key]);
            });

            const searchParamsInString = searchParams.toString();

            navigate(`${route}${searchParamsInString ? `?${searchParamsInString}` : ''}`);
        }
    };

    const currentStep = useMemo(() => getStepPositionByPathName(location.pathname), [location.pathname]);

    return {
        navigateTo,
        currentStep,
    };
};

export const hasPermission = (permissionCode: string, allowPermissionsList: string[] | null): boolean =>
    allowPermissionsList?.includes(permissionCode) || false;

export const thousandSeparator = (value: string, separator = ' '): string =>
    value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);

export const useAuth = () => {
    const ctx = useContext(AppContext);
    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    const processLogout = () => {
        ctx.showPopup(null);
        ctx.setInitData(null);
        ctx.setUserData(null);
        ctx.clearCurrentModel();
        localStorage.removeItem('token');
        localStorage.removeItem('initData');
        localStorage.removeItem('initDataFetched');
        localStorage.removeItem('lastActivity');
        navigate(ROUTE.LOGIN);
    };

    const logout = (): Promise<void> => {
        return new Promise((resolve) => {
            if (token && ctx.userData) {
                callLogoutAgent({ data: { loginName: ctx.userData.loginName }, token }).then(() => {
                    processLogout();
                    resolve();
                });
            } else {
                processLogout();
                resolve();
            }
        });
    };

    return {
        logout,
    };
};

export const checkFullTextSearch = (object: any | string, search: string): boolean => {
    const stringObject = typeof object !== 'string' ? Object.values(object).join('|') : object;
    const result = stringObject.match(new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i'));
    return !!result;
};

export const useContextHelpers = () => {
    const ctx = useContext(AppContext);
    const data = ctx.currentModel;

    const ownerId = data ? getOwner(data)?.ParticipantExternalId : null;
    const ownerParticipant =
        data && ownerId
            ? (getOwnerParticipant(data, ownerId) as ParticipantPersonProps<PersonalIdentificationFullProps>) ?? null
            : null;

    const isPep = ownerParticipant?.IsPep;

    const isF2F = data?.Settings.ArrangementType === ArrangementKind.F2F;
    const isRemote = data?.Settings.ArrangementType === ArrangementKind.Remote;

    const maxStepCode = data?.Settings?.CurrentStepCode;

    // custom
    const withoutDocumentPhotos = useMemo(() => isF2F && !isPep, [isF2F, isPep]);

    // TODO: can use this variables to other system components
    return {
        ownerId,
        ownerParticipant,
        isF2F,
        isRemote,
        isPep,
        conclusionType: data?.Settings?.ArrangementType,
        maxStepCode,
        // custom
        withoutDocumentPhotos,
    };
};

export const useProcessRoutes = () => {
    const { pathname } = useLocation();
    const { conclusionType, withoutDocumentPhotos } = useContextHelpers();

    const processRoutes = useMemo(
        () =>
            routes.filter((r: RouteProps) => {
                // výjimka na dokument photo
                if (r.code === STEP_CODE.DOCUMENT_PHOTO && withoutDocumentPhotos) {
                    return false;
                }
                // výstup filtru
                return r.showInProgressbar === true || (!!conclusionType && r.showInProgressbar === conclusionType);
            }),
        [conclusionType, withoutDocumentPhotos]
    );

    const currentProcessPosition = useMemo<number>(() => {
        return processRoutes.findIndex((r: RouteProps) => matchPath(r.path, pathname));
    }, [pathname, processRoutes]);

    const getRouteIndexByPath = useCallback(
        (pathName: string): number => processRoutes.findIndex((r: RouteProps) => matchPath(pathName, r.path)),
        [processRoutes]
    );
    const getNextRoute = useCallback(
        (stepCode: string): RouteProps | null => {
            const curr = processRoutes.findIndex((r: RouteProps) => r.code === stepCode);
            if (curr !== -1) {
                return processRoutes[curr + 1];
            }
            return null;
        },
        [processRoutes]
    );

    const getNextRouteIndex = useCallback(
        (stepCode?: string): number => {
            const curr = processRoutes.findIndex((r: RouteProps) => r.code === stepCode);
            if (curr) {
                return curr + 1;
            }
            return -1;
        },
        [processRoutes]
    );

    return {
        processRoutes,
        currentProcessPosition,
        getRouteIndexByPath,
        getNextRoute,
        getNextRouteIndex,
    };
};
