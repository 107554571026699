import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '../../components/Table';
import { AppContext } from '../../components/Context';
import {
    getPersonalInformation,
    getPersonalAddress,
    getBeneficiariesList,
    getPartnerInformation,
    getBankInformation,
} from './helpers';
import { ParticipantCompanyProps, ParticipantPersonProps, PersonalIdentificationFullProps } from '../../types/model';
import { getCheckedPartnerGroups, getPartnerType, getPersonOfInsurableInterest } from '../../utils';

const TableSummaryInformation = () => {
    const { t } = useTranslation();
    const { initData, userData, ...ctx } = useContext(AppContext);
    const data = ctx.currentModel;

    const personInsurableInterest = data ? getPersonOfInsurableInterest(data) : null;
    const personInsurableInterestId = personInsurableInterest?.ParticipantExternalId;
    const ownerId = data?.PolicyOwners[0]?.ParticipantExternalId;
    const ownerParticipant = data?.Participants?.find(
        (p) => p.ExternalId === ownerId
    ) as ParticipantPersonProps<PersonalIdentificationFullProps>;

    const id = ownerParticipant?.Identification;
    const idDetail = id?.PersonalIdentificationDetail;
    const insuredId = data?.InsuredPersons?.[0]?.ParticipantExternalId;

    const partnerData = data?.Participants?.find(
        (p) => p.ExternalId === personInsurableInterestId
    ) as ParticipantPersonProps<PersonalIdentificationFullProps>;

    const beneficiariesParticipants = data?.Participants?.filter(
        (p) => p && p.ExternalId !== insuredId && p.ExternalId !== ownerId && p.ExternalId !== partnerData?.ExternalId
    ) as Array<ParticipantPersonProps<PersonalIdentificationFullProps>>;

    // obmyšlená banka
    const companyParticipant = beneficiariesParticipants?.find((x) => 'CompanyIdentification' in x) as
        | ParticipantCompanyProps
        | undefined;

    // obmyšlené osoby
    const personParticipants = beneficiariesParticipants?.filter((b) => {
        return (
            b.$type !==
            'Eden.Paris.OnlineManagerService.SharedModels.Model.ParticipantCompany, Eden.Paris.OnlineManagerService.SharedModels'
        );
    });

    const hasCheckedPartnerGroup = useMemo(() => {
        if (data) {
            const res = getCheckedPartnerGroups(data, initData);
            return res && Array.isArray(res) && res.length > 0;
        }
    }, [data, initData]);

    const partnerType = data ? getPartnerType(data) : null;

    const getBeneficiarySplitPct = useCallback(
        (id: string) => {
            let splitPct = 0;
            data?.InsuredPersons.forEach((ip) => {
                ip.SuggestedInsurance?.ProtectionGroups.forEach((pg) => {
                    pg.SuggestedProtections.forEach((sp) => {
                        sp.Beneficiaries.forEach((b) => {
                            if (b.ParticipantExternalId === id) {
                                splitPct = b.SplitPct;
                            }
                        });
                    });
                });
            });
            return splitPct;
        },
        [data]
    );

    const getBeneficiaryNote = useCallback(
        (id: string) => {
            let note = null;
            data?.InsuredPersons.forEach((ip) => {
                ip.SuggestedInsurance?.ProtectionGroups.forEach((pg) => {
                    pg.SuggestedProtections.forEach((sp) => {
                        sp.Beneficiaries.forEach((b) => {
                            if (b.ParticipantExternalId === id) {
                                note = (b as any).Note;
                            }
                        });
                    });
                });
            });
            return note;
        },
        [data]
    );

    return (
        <Table
            mobileSimple
            cols={2}
            isCondensed
            className="mb-12 mdmax:mb-4"
            data={{
                categories: [
                    {
                        name: 'PersonalInformation',
                        label: t('common.personalInformation'),
                        sum: [getPersonalInformation(ownerParticipant, idDetail)],
                    },
                    {
                        name: 'RegisteredAddress',
                        label: t('common.registeredAddress'),
                        sum: [getPersonalAddress(id?.PermanentAddress)],
                    },
                    id?.ContactAddress
                        ? {
                              name: 'ContactAddress',
                              label: t('common.contactAddress'),
                              sum: [getPersonalAddress(id?.ContactAddress)],
                          }
                        : null,
                    id?.BankAccount
                        ? {
                              name: 'BankAccount',
                              label: t('common.bankAccount'),
                              sum: [
                                  <p data-test="bankAccount">
                                      {id?.BankAccount?.Iban?.toUpperCase()?.replace(new RegExp('^PL'), '')}
                                  </p>,
                              ],
                          }
                        : null,
                    (personParticipants || []).length > 0 ||
                    ((personParticipants || []).length === 0 && !companyParticipant)
                        ? {
                              name: 'Beneficiaries',
                              label: t('common.beneficiaries'),
                              sum: [getBeneficiariesList(personParticipants, getBeneficiarySplitPct)],
                          }
                        : null,
                    partnerType === 'partner' && hasCheckedPartnerGroup
                        ? {
                              name: 'Partner',
                              label: t('common.partner'),
                              sum: [getPartnerInformation(partnerData?.Identification)],
                          }
                        : null,
                    companyParticipant
                        ? {
                              name: 'CessionToTheBank',
                              label: t('common.cessionToTheBank'),
                              sum: [
                                  getBankInformation(
                                      initData,
                                      companyParticipant,
                                      getBeneficiarySplitPct,
                                      getBeneficiaryNote
                                  ),
                              ],
                          }
                        : null,
                ],
            }}
            data-test="summaryInformation"
        />
    );
};

export default TableSummaryInformation;
