import { Field, Form, FormikProps, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useContext, useEffect, useRef, useState } from 'react';
import { pdfjs } from 'react-pdf';

import { Head } from '../components/Head';
import { Card } from '../components/common/Card';
import { Grid } from '../components/Grid';
import { Select } from '../components/common/Select';
import { Input } from '../components/common/Input';
import { CustomFormik } from '../components/CustomFormik';
import {
    addDate,
    getHigherStep,
    getIdCardSelectList,
    getOwner,
    getSelectValueByCode,
    getToken,
    nowDate,
    subtractDate,
} from '../utils';
import { config } from '../config';
import { AppContext } from '../components/Context';
import { ParticipantPersonProps, PersonalIdentificationFullProps } from '../types/model';
import { ROUTE, STEP_CODE } from '../constants';
import { useAppNavigate, useContextHelpers } from '../utils/hooks';
import { AttachmentMetaDataType, callGetAttachmentMetaData } from '../apis/documents';
import AttachmentMetaDataView from '../components/AttachmentMetaDataView';
import LimitedAccess from '../components/LimitedAccess';
import { Layout } from '../components/Layout';
import { DetectFormikChanged } from '../components/common/DetectFormikChanged';
import DateInput from '../components/common/DateInput';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const IdDetails = () => {
    const { t } = useTranslation();
    const { navigateTo } = useAppNavigate();
    const now = nowDate();
    const { withoutDocumentPhotos } = useContextHelpers();
    const { initData, ...ctx } = useContext(AppContext);
    const data = ctx.currentModel;
    const token = getToken();
    const idList = getIdCardSelectList(initData);
    const policyOwnerId = data ? getOwner(data)?.ParticipantExternalId : null;
    const ownerParticipant =
        (data?.Participants?.find(
            (p) => p.ExternalId === policyOwnerId
        ) as ParticipantPersonProps<PersonalIdentificationFullProps>) ?? null;
    const otherParticipants = data?.Participants?.filter((p) => p.ExternalId !== policyOwnerId);
    const myFormikRef = useRef<FormikProps<any>>(null);
    const [documents, setDocuments] = useState<AttachmentMetaDataType[] | undefined>();

    const formSchema = Yup.object().shape({
        documentType: Yup.object().required(),
        idNumber: Yup.string().required(),
        issueDate: Yup.date().required().max(now).min(subtractDate(config.MAX_DOCUMENT_AGE, 'years')),
        expirationDate: Yup.date().required().min(now).max(addDate(config.MAX_DOCUMENT_AGE, 'years')),
        issuedBy: Yup.string().required().min(2),
    });

    const initialValues = {
        idNumber: ownerParticipant?.Identification?.IdCard?.IdCardNo ?? '',
        issueDate: ownerParticipant?.Identification?.IdCard?.IssuedDt ?? '',
        expirationDate: ownerParticipant?.Identification?.IdCard?.ExpiryDt ?? '',
        issuedBy: ownerParticipant?.Identification?.IdCard?.Issuer ?? '',
        documentType:
            (idList && getSelectValueByCode(idList, ownerParticipant?.Identification?.IdCard?.Kind)) ??
            (idList && idList[0]) ??
            '',
    };

    useEffect(() => {
        if (token && data?.ExternalId) {
            callGetAttachmentMetaData({ data: { onlinePolicyExternalId: data.ExternalId }, token })
                .then((response) => response.data)
                .then((result) => {
                    if (result.DocumentMetadataList) {
                        setDocuments(result.DocumentMetadataList);
                    }
                });
        }
    }, [token, data?.ExternalId]);

    return (
        <Layout>
            <LimitedAccess minStep={withoutDocumentPhotos ? STEP_CODE.IDENTITY_VERIFICATION : STEP_CODE.DOCUMENT_PHOTO}>
                <Head heading1={t('pages.personalId.title')} heading2={t('pages.personalId.subtitle')} />
                {documents && (
                    <div className="mb-6 lg:grid lg:grid-cols-2 lg:gap-2">
                        {documents
                            .filter((document) =>
                                ['idcard'].includes((document.OnlinePolicyAttachmentTypeCode || '').toLowerCase())
                            )
                            .map((document, index) => (
                                <div key={`document-${document.ExternalId}`} data-test={`documentImage${index + 1}`}>
                                    <AttachmentMetaDataView key={`attachment-${index}`} document={document} />
                                </div>
                            ))}
                    </div>
                )}
                <CustomFormik
                    initialValues={initialValues}
                    onSubmit={(v) => {
                        if (data) {
                            const newModel = {
                                ...data,
                                Settings: {
                                    ...data.Settings,
                                    CurrentStepCode: getHigherStep(data.Settings.CurrentStepCode, STEP_CODE.ID_DETAILS),
                                },
                            };

                            ctx.setCurrentModel(newModel);
                            ctx.saveCurrentModel(newModel);
                        }
                        navigateTo(ROUTE.PERSONAL_DATA);
                    }}
                    validationSchema={formSchema}
                    customRender
                    passedRef={myFormikRef}
                >
                    <Form>
                        <DetectFormikChanged
                            onChange={(v: FormikValues) => {
                                if (ownerParticipant && ownerParticipant?.Identification && v && data) {
                                    const updatedParticipant = ownerParticipant;

                                    updatedParticipant.Identification.IdCard = {
                                        ...updatedParticipant.Identification.IdCard,
                                        ExpiryDt: v?.expirationDate || null,
                                        IdCardNo: v?.idNumber || null,
                                        IssuedDt: v?.issueDate || null,
                                        Issuer: v?.issuedBy || null,
                                        Kind: v?.documentType.value,
                                    };

                                    ctx.setCurrentModel({
                                        ...data,
                                        Participants: [updatedParticipant, ...(otherParticipants || [])],
                                    });
                                }
                            }}
                        />
                        <Card data-test="idDetailsCard">
                            <Grid>
                                <Field
                                    name="documentType"
                                    component={Select}
                                    label={t('common.formFields.documentType')}
                                    isCenter
                                    isSearchable={false}
                                    options={idList}
                                    data-test="documentType"
                                />
                                <Field
                                    name="idNumber"
                                    isCenter
                                    label={t('common.formFields.identificatorNumber')}
                                    component={Input}
                                    maxLength={50}
                                    data-test="idNumber"
                                />
                                <Field
                                    name="issueDate"
                                    isCenter
                                    label={t('common.formFields.issueDate')}
                                    component={DateInput}
                                    minDate={new Date(subtractDate(config.MAX_DOCUMENT_AGE, 'years'))}
                                    maxDate={new Date(nowDate())}
                                    data-test="issueDate"
                                />
                                <Field
                                    name="expirationDate"
                                    isCenter
                                    label={t('common.formFields.expirationDate')}
                                    component={DateInput}
                                    minDate={new Date(addDate(1, 'days'))}
                                    maxDate={new Date(addDate(config.MAX_DOCUMENT_AGE, 'years'))}
                                    data-test="expirationDate"
                                />
                                <Field
                                    name="issuedBy"
                                    isCenter
                                    label={t('common.formFields.issuedBy')}
                                    component={Input}
                                    data-test="issuedBy"
                                />
                            </Grid>
                        </Card>
                    </Form>
                </CustomFormik>
            </LimitedAccess>
        </Layout>
    );
};
