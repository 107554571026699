import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import { Head } from '../../components/Head';
import { Card } from '../../components/common/Card';
import { Title } from '../../components/common/Title';
import { List } from '../../components/common/List';
import { AppContext } from '../../components/Context';
import { getOwner } from '../../utils';
import { IdentityConfirmatorType } from '../../types/enums/IdentityConfirmatorType';
import { ROUTE } from '../../constants';
import { Layout } from '../../components/Layout';

export const VerifyIdentity = () => {
    const { t } = useTranslation();
    const ctx = useContext(AppContext);
    const data = ctx.currentModel;
    const policyOwner = data ? getOwner(data) : null;
    const navigate = useNavigate();
    const { identityId } = useParams();

    const sideTranslation =
        policyOwner?.IdentityConfirmation?.IdentityConfirmatorType?.toString() ===
        IdentityConfirmatorType.Agent.toString()
            ? 'agentSide'
            : 'clientSide';

    const pageTranslation: any = t(`pages.clientVerifyIdentity.${sideTranslation}`, { returnObjects: true });

    const handleClick = () => {
        navigate(ROUTE.CLIENT_VERIFY_SNAP.replace(':identityId', identityId || ''));
    };

    return (
        <Layout hideNavigation hideProgressBar hideSaveButton hideUserMenu continueCallback={handleClick}>
            <Head
                heading1={t(`pages.clientVerifyIdentity.${sideTranslation}.title`)}
                heading2={t(`pages.clientVerifyIdentity.${sideTranslation}.subtitle`)}
            />

            <Card data-test="clientVerifyIdentityCard">
                <Title
                    tag="h2"
                    size="md"
                    fontWeight="medium"
                    className="mb-8 inline-flex items-center justify-center gap-x-6"
                    data-test="clientVerifyIdentityTitle"
                >
                    {t(`pages.clientVerifyIdentity.${sideTranslation}.card.title`)}
                </Title>

                <List type="ordered" items={pageTranslation.card.list} data-test="clientVerifyIdentityTexts" />
            </Card>
        </Layout>
    );
};
