import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from './common/ProtectedRoute';
import { isTokenExpired, parseUserDataFromToken } from '../utils';
import { Error } from '../pages/other/Error';
import { AppContext } from './Context';
import { routes } from '../routes';
import { ROUTE } from '../constants';

export const Main = () => {
    const { userData, setUserData } = useContext(AppContext);
    const [retrievingUserData, setRetrievingUserData] = useState(true);
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (token && !userData && !isTokenExpired(token)) {
            setUserData(parseUserDataFromToken(token));
        }
        setRetrievingUserData(false);
        // eslint-disable-next-line
    }, [token, userData]);

    return (
        <Routes>
            {routes.map((r, j) => (
                <Route
                    key={j}
                    path={r.path}
                    element={
                        <ProtectedRoute
                            isAuthorizationProtected={r.isAuthorizationProtected}
                            isDataProtected={!!r.isDataProtected}
                            permission={r.permission}
                            retrievingUserData={retrievingUserData}
                        >
                            {r.element}
                        </ProtectedRoute>
                    }
                />
            ))}
            <Route path={ROUTE.ERROR_404} element={<Error type="not-found" />} />
            <Route path="*" element={<Navigate replace to="/404" />} />
            <Route path={ROUTE.UNACCESSIBLE} element={<Error type="forbidden" />} />
            <Route path="/" element={<Navigate to={ROUTE.LOGIN} />} />
            <Route path={ROUTE.ERROR_500} element={<Error type="server" />} />
        </Routes>
    );
};
