import { useContext, useEffect } from 'react';

import { AppContext } from '../components/Context';
import Loader from '../components/common/Loader';
import { getNextRouteByStepCode } from '../utils';
import { useAppNavigate } from '../utils/hooks';
import { ROUTE } from '../constants';
import { Layout } from '../components/Layout';

export const ContinueDraft = () => {
    const ctx = useContext(AppContext);
    const data = ctx.currentModel;
    const { navigateTo } = useAppNavigate();

    useEffect(() => {
        if (data) {
            if (data.Settings.CurrentStepCode) {
                const route = getNextRouteByStepCode(data.Settings.CurrentStepCode);
                if (route?.path) {
                    navigateTo(route.path);
                } else {
                    navigateTo(ROUTE.CONCLUSION);
                }
            } else {
                navigateTo(ROUTE.DASHBOARD_MAIN);
            }
        }
        // eslint-disable-next-line
    }, [data]);

    return (
        <Layout hideNavigation hideUserMenu hideFooter>
            <Loader />
        </Layout>
    );
};
