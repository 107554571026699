import { ReactElement } from 'react';

import { MainMenu } from './pages/MainMenu';
import { Conclusion } from './pages/Conclusion';
import { DocumentPhoto } from './pages/DocumentPhoto';
import { Finish } from './pages/Finish';
import { FinishRemote } from './pages/FinishRemote';
import { Login } from './pages/Login';
import { MojeId } from './pages/MojeId/MojeId';
import { PackageSelection } from './pages/PackageSelection';
import { ResetPassword } from './pages/ResetPassword';
import Summary from './pages/Summary';
import { SummaryDocuments } from './pages/SummaryDocuments';
import { SummaryPreparing } from './pages/SummaryPreparing';
import { ClientMojeId } from './pages/client/ClientMojeId';
import { ConfirmPersonalData } from './pages/client/ConfirmPersonalData';
import { ContactApprovement } from './pages/client/ContactApprovement';
import { IdentificationFinal } from './pages/client/IdentificationFinal';
import { ConfirmationData } from './pages/client/ConfirmationData';
import { VerifyIdentity } from './pages/client/VerifyIdentity';
import { VerifyIdentityFinal } from './pages/client/VerifyIdentityFinal';
import { VerifySnap } from './pages/client/VerifySnap';
import { BasicDates } from './pages/BasicDates';
import { Beneficiaries } from './pages/Beneficiaries';
import { ContactsCheck } from './pages/ContactsCheck';
import { IdDetails } from './pages/IdDetails';
import { IdentityVerification } from './pages/IdentityVerification';
import Questionnaire from './pages/Questionnaire';
import { PackageAdjustment } from './pages/PackageAdjustment/PackageAdjustment';
import { PersonalData } from './pages/PersonalData/PersonalData';
import { Design } from './pages/other/Design';
import { InitPassword } from './pages/InitPassword';
import { ChangePassword } from './pages/ChangePassword';
import { SummaryFail } from './pages/SummaryFail';
import Dashboard from './pages/consultant/Dashboard';
import { AGENT_PORTAL_RIGHTS, ROUTE, STEP_CODE } from './constants';
import { ArrangementKind } from './types/enums/ArrangementKind';
import { InvalidLink } from './pages/InvalidLink';
import { ContinueDraft } from './pages/ContinueDraft';
import { AutopayConfirmation } from './pages/AutopayConfirmation';
import { MojeIdAuth } from './pages/MojeIdAuth';
import { AutopayPaymentMethod } from './pages/AutopayPaymentMethod';
import { AutopayPolicyPaid } from './pages/AutopayPolicyPaid';
import DamageEventForm from './pages/DamageEvent';

export interface RouteProps {
    code?: string;
    element: ReactElement;
    title: string;
    path: string;
    showInProgressbar: boolean | number;
    order?: number;
    isAuthorizationProtected: boolean;
    isDataProtected?: boolean;
    permission?: string;
}

export const routes: Array<RouteProps> = [
    {
        element: <Login />,
        title: 'Login',
        path: ROUTE.LOGIN,
        showInProgressbar: false,
        isAuthorizationProtected: false,
    },
    {
        element: <ResetPassword />,
        title: 'Reset Password',
        path: ROUTE.RESET_PASSWORD,
        showInProgressbar: false,
        isAuthorizationProtected: false,
    },
    {
        element: <ChangePassword />,
        title: 'Change Password',
        path: ROUTE.CHANGE_PASSWORD,
        showInProgressbar: false,
        isAuthorizationProtected: true,
    },
    {
        element: <InitPassword />,
        title: 'Init Password',
        path: ROUTE.INIT_PASSWORD,
        showInProgressbar: false,
        isAuthorizationProtected: false,
    },
    {
        code: STEP_CODE.MAIN_MENU,
        element: <MainMenu />,
        title: 'Main Menu',
        path: ROUTE.MAIN_MENU,
        showInProgressbar: true,
        isAuthorizationProtected: true,
    },
    {
        code: STEP_CODE.CONCLUSION,
        element: <Conclusion />,
        title: 'Conclusion',
        path: ROUTE.CONCLUSION,
        showInProgressbar: true,
        isAuthorizationProtected: true,
        isDataProtected: false,
        permission: AGENT_PORTAL_RIGHTS.CREATE_POLICY,
    },
    {
        code: STEP_CODE.QUESTIONNAIRE,
        element: <Questionnaire />,
        title: 'Questionnaire',
        path: ROUTE.QUESTIONNAIRE,
        showInProgressbar: true,
        isAuthorizationProtected: true,
        isDataProtected: true,
    },
    {
        code: STEP_CODE.PACKAGE_CHOICE,
        element: <PackageSelection />,
        title: 'Package choice',
        path: ROUTE.PACKAGE_CHOICE,
        showInProgressbar: true,
        isAuthorizationProtected: true,
        isDataProtected: true,
    },
    {
        code: STEP_CODE.BASIC_DATA,
        element: <BasicDates />,
        title: 'Basic info',
        path: ROUTE.BASIC_DATA,
        showInProgressbar: true,
        isAuthorizationProtected: true,
        isDataProtected: true,
    },
    {
        code: STEP_CODE.COVER_ADJUST,
        element: <PackageAdjustment />,
        title: 'Cover adjust',
        path: ROUTE.COVER_ADJUST,
        showInProgressbar: true,
        isAuthorizationProtected: true,
        isDataProtected: true,
    },
    {
        code: STEP_CODE.CONTACTS_CHECK,
        element: <ContactsCheck />,
        title: 'Contacts check',
        path: ROUTE.CONTACTS_CHECK,
        showInProgressbar: true,
        isAuthorizationProtected: true,
        isDataProtected: true,
    },
    {
        element: <ContactApprovement />,
        title: 'Contacts check - approve',
        path: ROUTE.CONTACTS_CHECK_APPROVE,
        showInProgressbar: false,
        isAuthorizationProtected: false,
    },
    {
        code: STEP_CODE.IDENTITY_VERIFICATION,
        element: <IdentityVerification />,
        title: 'Identity Verification',
        path: ROUTE.IDENTITY_VERIFICATION,
        showInProgressbar: true,
        isAuthorizationProtected: true,
        isDataProtected: true,
    },
    {
        element: <MojeId />,
        title: 'Moje Id',
        path: ROUTE.MOJE_ID,
        showInProgressbar: false,
        isAuthorizationProtected: false,
    },
    {
        code: STEP_CODE.DOCUMENT_PHOTO,
        element: <DocumentPhoto />,
        title: 'Document Photo',
        path: ROUTE.DOCUMENT_PHOTO,
        showInProgressbar: true,
        isAuthorizationProtected: true,
        isDataProtected: true,
    },
    {
        code: STEP_CODE.ID_DETAILS,
        element: <IdDetails />,
        title: 'ID details',
        path: ROUTE.ID_DETAILS,
        showInProgressbar: true,
        isAuthorizationProtected: true,
        isDataProtected: true,
    },
    {
        code: STEP_CODE.PERSONAL_DATA,
        element: <PersonalData />,
        title: 'Personal data',
        path: ROUTE.PERSONAL_DATA,
        showInProgressbar: true,
        isAuthorizationProtected: true,
        isDataProtected: true,
    },
    {
        code: STEP_CODE.BENEFICIARIES,
        element: <Beneficiaries />,
        title: 'Beneficiaries',
        path: ROUTE.BENEFICIARIES,
        showInProgressbar: true,
        isAuthorizationProtected: true,
        isDataProtected: true,
    },
    {
        code: STEP_CODE.SUMMARY,
        element: <Summary />,
        title: 'Summary',
        path: ROUTE.SUMMARY,
        showInProgressbar: true,
        isAuthorizationProtected: true,
        isDataProtected: true,
    },
    {
        code: STEP_CODE.SUMMARY_PREPARING,
        element: <SummaryPreparing />,
        title: 'Summary Preparing',
        path: ROUTE.SUMMARY_PREPARING,
        showInProgressbar: false,
        isAuthorizationProtected: true,
        isDataProtected: true,
    },
    {
        code: STEP_CODE.SUMMARY_DOCUMENTS,
        element: <SummaryDocuments />,
        title: 'Summary Documents',
        path: ROUTE.SUMMARY_DOCUMENTS,
        showInProgressbar: ArrangementKind.F2F,
        isAuthorizationProtected: true,
        isDataProtected: true,
    },
    {
        element: <Finish />,
        title: 'Finish',
        path: ROUTE.FINISH,
        showInProgressbar: ArrangementKind.F2F,
        isAuthorizationProtected: true,
        isDataProtected: true,
    },
    {
        code: STEP_CODE.FINISH_REMOTE,
        element: <FinishRemote />,
        title: 'Finish',
        path: ROUTE.FINISH_REMOTE,
        showInProgressbar: ArrangementKind.Remote,
        isAuthorizationProtected: true,
        isDataProtected: true,
    },
    {
        element: <ConfirmationData />,
        title: 'Confirmation Data',
        path: ROUTE.CONFIRMATION_DATA,
        showInProgressbar: false,
        isAuthorizationProtected: false,
    },
    {
        element: <ClientMojeId />,
        title: 'Client Moje Id',
        path: ROUTE.CLIENT_MOJE_ID,
        showInProgressbar: false,
        isAuthorizationProtected: false,
    },
    {
        element: <ConfirmPersonalData />,
        title: 'Client Personal Data',
        path: ROUTE.CLIENT_PERSONAL_DATA,
        showInProgressbar: false,
        isAuthorizationProtected: false,
    },
    {
        element: <IdentificationFinal />,
        title: 'Client Identification Final',
        path: ROUTE.CLIENT_IDENTIFICATION_FINAL,
        showInProgressbar: false,
        isAuthorizationProtected: false,
    },
    {
        element: <VerifyIdentity />,
        title: 'Client Verify Identity',
        path: ROUTE.CLIENT_VERIFY_IDENTITY,
        showInProgressbar: false,
        isAuthorizationProtected: false,
    },
    {
        element: <VerifySnap />,
        title: 'Client Verify Snap',
        path: ROUTE.CLIENT_VERIFY_SNAP,
        showInProgressbar: false,
        isAuthorizationProtected: false,
    },
    {
        element: <VerifyIdentityFinal />,
        title: 'Client Verify Identity Final',
        path: ROUTE.CLIENT_VERIFY_IDENTITY_FINAL,
        showInProgressbar: false,
        isAuthorizationProtected: false,
    },
    {
        element: <Design />,
        title: 'Design System',
        path: ROUTE.DESIGN_SYSTEM,
        showInProgressbar: false,
        isAuthorizationProtected: false,
    },
    {
        element: <SummaryFail />,
        title: 'Summary Fail',
        path: ROUTE.SUMMARY_FAIL,
        showInProgressbar: false,
        isAuthorizationProtected: true,
        isDataProtected: true,
    },
    {
        element: <Dashboard />,
        title: 'Dashboard',
        path: '/dashboard/*',
        showInProgressbar: false,
        isAuthorizationProtected: true,
    },
    {
        element: <InvalidLink />,
        title: 'Invalid link',
        path: ROUTE.INVALID_LINK,
        showInProgressbar: false,
        isAuthorizationProtected: false,
    },
    {
        element: <ContinueDraft />,
        title: 'Continue',
        path: ROUTE.CONTINUE_DRAFT,
        showInProgressbar: false,
        isAuthorizationProtected: true,
    },
    {
        element: <AutopayConfirmation />,
        title: 'Autopay - Confirmation',
        path: ROUTE.AUTOPAY_CONFIRMATION,
        showInProgressbar: false,
        isAuthorizationProtected: false,
        isDataProtected: false,
    },
    {
        element: <AutopayPaymentMethod />,
        title: 'Autopay - Choose payment method',
        path: ROUTE.AUTOPAY_PAYMENT_METHOD,
        showInProgressbar: false,
        isAuthorizationProtected: false,
        isDataProtected: false,
    },
    {
        element: <AutopayPolicyPaid />,
        title: 'Autopay - Policy paid',
        path: ROUTE.AUTOPAY_POLICY_PAID,
        showInProgressbar: false,
        isAuthorizationProtected: false,
        isDataProtected: false,
    },
    {
        element: <MojeIdAuth />,
        title: 'MojeID',
        path: ROUTE.MOJE_ID_AUTH,
        showInProgressbar: false,
        isAuthorizationProtected: false,
        isDataProtected: false,
    },
    // formulář škodní události
    {
        element: <DamageEventForm />,
        title: 'Damage event',
        path: ROUTE.DAMAGE_EVENT,
        showInProgressbar: false,
        isAuthorizationProtected: true,
        isDataProtected: false,
    },
];
