import React from 'react';
import { useTranslation } from 'react-i18next';
import FooterMobileMenu from '../../components/FooterMobileMenu';
import FooterMobileMenuItem from '../../components/FooterMobileMenuItem';
import { useAppNavigate } from '../../utils/hooks';
import { Button } from '../../components/common/Button';
import { ROUTE } from '../../constants';

const FooterContent = () => {
    const { t } = useTranslation();
    const { navigateTo } = useAppNavigate();

    return (
        <>
            <div className="flex grow flex-row flex-nowrap justify-end gap-x-4 mdmax:hidden">
                <Button isOutlined onClick={() => console.log('api call, send sms')} data-test="mojeIdSendSmsAgain">
                    {t('common.sendSmsAgain')}
                </Button>
                <Button
                    isOutlined
                    onClick={() => navigateTo(ROUTE.IDENTITY_VERIFICATION)}
                    className="mr-6"
                    data-test="mojeIdSendDifferentIdentification"
                >
                    {t('common.selectDifferentIdentification')}
                </Button>
            </div>

            <div className="relative ml-auto md:hidden">
                <FooterMobileMenu
                    control={
                        <Button isOutlined icon="dots" className="ml-auto mr-6" data-test="mojeIdMoreButton">
                            {t('common.more')}
                        </Button>
                    }
                >
                    <FooterMobileMenuItem
                        onClick={() => console.log('api call, send sms')}
                        data-test="mojeIdMoreSendSmsAgain"
                    >
                        {t('common.sendSmsAgain')}
                    </FooterMobileMenuItem>
                    <FooterMobileMenuItem
                        onClick={() => console.log('api call, selectDifferentIdentification')}
                        data-test="mojeIdMoreSendDifferentIdentification"
                    >
                        {t('common.selectDifferentIdentification')}
                    </FooterMobileMenuItem>
                </FooterMobileMenu>
            </div>
        </>
    );
};

export default FooterContent;
