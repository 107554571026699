import cn from 'classnames';
import { FieldProps, FormikProps, FormikValues } from 'formik';
import { ChangeEvent, KeyboardEvent, ReactElement, WheelEvent, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { FormError } from './FormError';
import { Help } from './Help';
import { config } from '../../config';
import { FormFieldSize } from '../../types';
import { getDataTest } from '../../utils';

export interface InputProps {
    label?: string;
    type?: 'text' | 'password' | 'date' | 'number' | 'smsCode';
    isCenter?: boolean;
    field?: FieldProps['field'];
    className?: string;
    initialValue?: number;
    placeholder?: string;
    helpText?: string;
    isDisabled?: boolean;
    handleValue: any;
    value?: string;
    fieldClassName?: string;
    min?: number | string;
    max?: number | string;
    form: FormikProps<FormikValues>;
    helpLocation?: 'label' | 'input';
    maxLength?: number;
    withoutHelperText?: boolean;
    autoFocus?: boolean;
    errorMessage?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    isStringNumber?: boolean;
    thousandSeparator?: boolean;
    size?: FormFieldSize;
    // vyloučení formiku z inputu
    staticValue?: boolean;
}

const safeKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
const safeNumberKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

export const Input = ({
    label,
    type = 'text',
    isCenter,
    className,
    field,
    placeholder,
    handleValue,
    helpText,
    isDisabled = false,
    fieldClassName,
    min = 0,
    max = 0,
    form,
    initialValue,
    value,
    helpLocation = 'input',
    maxLength = config.MAX_INPUT_LENGTH_DEFAULT,
    withoutHelperText = false,
    autoFocus = false,
    errorMessage,
    onChange,
    isStringNumber,
    thousandSeparator = false,
    size = 'medium',
    staticValue = false,
    ...props
}: InputProps): ReactElement => {
    const addCommas = (num: string) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    const removeNonNumeric = (num: string) => num.toString().replace(/[^0-9]/g, '');

    const inValue = useMemo(() => {
        if (staticValue) {
            return value;
        }
        return field?.value || initialValue || '';
    }, [staticValue, value, field?.value, initialValue]);

    const updateValue = (v: string): string => {
        if (type === 'number' && thousandSeparator) {
            return addCommas(removeNonNumeric(v));
        }

        return v;
    };

    const parseValue = (v: string): string => {
        if (type === 'number' && thousandSeparator) {
            return removeNonNumeric(v);
        }

        if (type === 'smsCode') {
            return removeNonNumeric(v);
        }

        return v;
    };

    const customProps = useMemo(() => {
        if (type === 'number') {
            return {
                onWheel: (e: WheelEvent<HTMLInputElement>) => {
                    e.currentTarget.blur();
                },
            };
        }

        return {};
    }, [type]);

    const inErrorMessage = useMemo(
        () => (field?.name && form.errors[field.name] ? form.errors[field.name]?.toString() : errorMessage),
        [field?.name, form.errors, errorMessage]
    );

    const isFunctionKey = (e: KeyboardEvent<HTMLInputElement>): boolean => {
        return (
            ((e.ctrlKey || e.metaKey) && e.key === 'x') ||
            ((e.ctrlKey || e.metaKey) && e.key === 'c') ||
            ((e.ctrlKey || e.metaKey) && e.key === 'v') ||
            ((e.ctrlKey || e.metaKey) && e.key === 'a') ||
            ((e.ctrlKey || e.metaKey) && e.key === 'r')
        );
    };

    return (
        <div className={cn(className, 'flex w-full flex-col')}>
            {label && (
                <label
                    className={cn(
                        'mb-2 flex cursor-pointer flex-row gap-x-2 text-sm text-darkPurple',
                        isCenter ? 'justify-center text-center' : 'pl-4',
                        isDisabled && 'pointer-events-auto cursor-auto text-gray',
                        size === 'small' && 'text-xs'
                    )}
                    htmlFor={isDisabled ? undefined : field?.name}
                    data-test={getDataTest(props, 'Label')}
                >
                    {label}
                    {helpText && helpLocation === 'label' && (
                        <Help text={helpText} data-test={getDataTest(props, 'Help')} />
                    )}
                </label>
            )}

            <div className="relative flex w-full items-center gap-x-2">
                <input
                    {...props}
                    id={field?.name}
                    name={field?.name}
                    type={type === 'smsCode' || thousandSeparator ? 'text' : type}
                    value={updateValue(inValue)}
                    autoFocus={autoFocus}
                    onKeyDown={(e) => {
                        if (type === 'number' || isStringNumber) {
                            if (!safeNumberKeys.includes(e.key) && !safeKeys.includes(e.key) && !isFunctionKey(e)) {
                                e.preventDefault();
                            }

                            if (maxLength) {
                                const selection = window.getSelection();
                                const tar = e.target as HTMLInputElement;
                                const len = tar.value.length;

                                // Unlock field editation while field content selected by mouse
                                if (!safeKeys.includes(e.key) && selection?.type !== 'Range' && !isFunctionKey(e)) {
                                    if (len >= maxLength) {
                                        e.preventDefault();
                                    }
                                }
                            }
                        } else if (type === 'smsCode') {
                            if (!/[0-9]/.test(e.key) && !safeKeys.includes(e.key)) {
                                e.preventDefault();
                            }
                        } else {
                            return undefined;
                        }
                    }}
                    onChange={(e) => {
                        const val = parseValue(e?.target.value);
                        const maxInt = typeof max === 'string' ? parseInt(max) : max;

                        if (isDisabled || (type === 'number' && (min || max) && parseInt(val) > maxInt)) {
                            e.preventDefault();
                        } else {
                            const newEvent = {
                                ...e,
                                target: {
                                    ...e.target,
                                    name: e.target.name,
                                    value: parseValue(e?.target.value),
                                },
                            };
                            field?.onChange(newEvent);
                            onChange && onChange(newEvent as any);
                            if (handleValue) {
                                handleValue((prevState: any) => {
                                    const newState = prevState;
                                    return { ...newState, [`${field?.name}`]: parseValue(e.target?.value) };
                                });
                            }
                        }
                    }}
                    min={min}
                    max={max}
                    maxLength={(type === 'text' || type === 'smsCode') && maxLength ? maxLength : undefined}
                    placeholder={placeholder}
                    className={cn(
                        twMerge(
                            'inline-block w-full shrink-0 grow rounded-full border !bg-white px-4 [&:focus]:border-darkPurple',
                            size === 'medium' && 'h-[3rem] md:h-[3.25rem]',
                            size === 'small' && 'h-10',
                            type === 'date' && 'inline-flex items-center justify-start',
                            isCenter && 'text-center',
                            type === 'date' && isCenter && 'justify-center',
                            inErrorMessage ? 'border-red' : 'border-purple',
                            isDisabled && 'pointer-events-none border-gray !text-gray',
                            fieldClassName
                        )
                    )}
                    {...customProps}
                    data-test={getDataTest(props, 'Input')}
                />
                {helpText && helpLocation === 'input' && (
                    <Help text={helpText} data-test={getDataTest(props, 'Help')} />
                )}
            </div>

            {!withoutHelperText && (
                <FormError
                    name={field?.name}
                    className={cn('mt-2', isCenter ? 'text-center' : 'pl-4')}
                    data-test={getDataTest(props, 'Error')}
                />
            )}

            {errorMessage && (
                <FormError
                    error={errorMessage}
                    className={cn('mt-2', isCenter ? 'text-center' : 'pl-4')}
                    data-test={getDataTest(props, 'Error')}
                />
            )}
        </div>
    );
};
