import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import cn from 'classnames';

import { MenuDivider } from './MenuDivider';
import { MenuItem } from './MenuItem';
import { LOCALES, ROUTE } from '../constants';
import { AppContext } from './Context';
import { isDevelopment } from '../utils';
import { useAppNavigate } from '../utils/hooks';
import { UserDataType } from '../types';
import i18n from '../i18n';

interface HeaderMenuProps {
    hideSaveButton?: boolean;
    setMenuActive: Dispatch<SetStateAction<boolean>>;
    handleLogout: () => void;
    className?: string;
}

export const HeaderMenu = ({ hideSaveButton = false, setMenuActive, handleLogout, className }: HeaderMenuProps) => {
    const { t } = useTranslation();
    const { navigateTo } = useAppNavigate();
    const { showPopup, userData, ...ctx } = useContext(AppContext);
    const data = ctx.currentModel;
    const token = localStorage.getItem('token');
    const currLang = i18n.language;
    const otherLang = LOCALES.find((l) => l.code !== currLang);
    const [animComplete, setAnimComplete] = useState(false);
    const [searchParams] = useSearchParams();

    const handleSave = () => {
        if (token && data) {
            ctx.saveCurrentModel(data);
        }
    };

    return (
        <motion.div
            initial={{
                height: 0,
                opacity: 0,
            }}
            animate={{
                height: 'auto',
                opacity: 1,
            }}
            exit={{
                height: 0,
                opacity: 0,
            }}
            onAnimationComplete={() => setAnimComplete(true)}
            onAnimationStart={() => setAnimComplete(false)}
            className={cn(
                className,
                'min-w-60 absolute right-0 top-full mt-4 max-h-[calc(100vh-4.375rem)] overflow-y-scroll rounded-sm border-1 border-lightGray2 bg-white px-2 py-4 text-sm shadow',
                animComplete ? 'scrollbar' : 'invisible-scrollbar'
            )}
            data-test="userMenu"
        >
            <div style={{ minWidth: '180px' }} data-test="userMenu-content">
                {userData && (
                    <>
                        <div className="px-2 text-purple" data-test="userMenu-name">
                            {userData?.name}
                        </div>
                        {userData?.email && (
                            <div className="px-2 text-xs text-gray" data-test="userMenu-email">
                                {userData?.email}
                            </div>
                        )}
                        <MenuDivider className="mt-4" />
                    </>
                )}

                <ul className="m-0 flex list-none flex-col gap-y-1 p-0">
                    {!hideSaveButton && (
                        <MenuItem
                            icon="save"
                            text={t('common.menu.save')}
                            setMenuActive={setMenuActive}
                            onClick={() => handleSave()}
                            data-test="userMenu-save"
                            className="lg:hidden"
                        />
                    )}
                    <MenuItem
                        icon="category"
                        text={t('common.menu.mainMenu')}
                        setMenuActive={setMenuActive}
                        data-test="userMenu-mainMenu"
                        onClick={() =>
                            navigateTo(ROUTE.MAIN_MENU, {
                                ignoreLimits: !searchParams.get('id'),
                            })
                        }
                    />
                    <MenuItem
                        icon="lock"
                        text={t('common.menu.changePassword')}
                        setMenuActive={setMenuActive}
                        data-test="userMenu-changePassword"
                        onClick={() => navigateTo(ROUTE.CHANGE_PASSWORD)}
                    />
                    <MenuItem
                        icon="info"
                        text={t('common.menu.reportBug')}
                        setMenuActive={setMenuActive}
                        data-test="userMenu-reportBug"
                        onClick={() => showPopup('report-bug')}
                    />
                </ul>

                <MenuDivider />

                <ul className="m-0 flex list-none flex-col gap-y-1 p-0">
                    {isDevelopment() && otherLang && (
                        <MenuItem
                            icon="world"
                            text={otherLang?.title}
                            data-test="userMenu-changeLanguage"
                            onClick={() => {
                                i18n.changeLanguage(otherLang?.code);
                                ctx.setUserData({
                                    ...(userData as UserDataType),
                                    language: otherLang.code,
                                });
                            }}
                            setMenuActive={setMenuActive}
                        />
                    )}
                    <MenuItem
                        icon="logout"
                        text={t('common.menu.logout')}
                        className="font-medium"
                        data-test="userMenu-logout"
                        onClick={handleLogout}
                        setMenuActive={setMenuActive}
                    />
                </ul>
            </div>
        </motion.div>
    );
};
