export enum SectionCode {
    PRODUCT_SPECIFICATION = 'productSpecification',
    FOR_WHO_IS_PRODUCT = 'forWhoIsProduct',
    GLOSSARY = 'glossary',
    FAQ = 'faq',
    DOCUMENTS = 'documents',
}

export const sections: string[] = [
    SectionCode.PRODUCT_SPECIFICATION,
    SectionCode.FOR_WHO_IS_PRODUCT,
    SectionCode.GLOSSARY,
    SectionCode.FAQ,
    SectionCode.DOCUMENTS,
];

export interface ProductSpecificationItemType {
    title: string;
    content: string;
    list: Array<{
        title: string;
        content: string;
    }>;
}

export enum KnowledgeDocumentFileName {
    Uposazony = 'Uposazony.pdf',
    DefinicjeZOWU = 'definicje-z-OWU.pdf',
    MojaSimpleaManual = 'mojaSimplea1.0-sciezka-sprzedazy.pdf',
    OkresKarencji = 'Okres-karencji.pdf',
    FaQ = 'QA.pdf',
    RynekDocelowy = 'Rynek-docelowy.pdf',
    WylaczeniaOdpowiedzialnosci = 'Wylaczenia-odpowiedzialnosci.pdf',
    ZalacznikNr1OWU = 'Zalacznik-nr-1-OWU.pdf',
    ZalacznikNr2OWU = 'Zalacznik-nr-2-OWU.pdf',
    ZalacznikNr3OWU = 'Zalacznik-nr-3-OWU.pdf',
    ZalacznikNr4OWU = 'Zalacznik-nr-4-OWU.pdf',
    ZalacznikNr5Assistance = 'Zalacznik-nr-5-Assistance.pdf',
    WykazFunkcjiPep = 'Wykaz-funkcji-PEP.pdf',
    PepKwestionariusz = 'PEP_kwestionariusz_06-2024.pdf',
    DefinicjaPep = 'Definicja-Czlonkowie-rodziny-oraz-Bliscy-wspolpracownicy-PEP.pdf',
    GTC = 'GTC_15-01-2024_2.pdf',
    SimpleaSalesPath = 'Simplea_Sales_Path-IT_security.pdf',
}
