import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { MenuItem } from './DashboardMenu';

interface DashboardMenuItemProps {
    item: MenuItem;
    isActive: boolean;
}

const DashboardMenuItem = ({ item, isActive, ...props }: DashboardMenuItemProps) => {
    if (item.isDisabled) {
        return (
            <li {...props}>
                <span className="color-black group mb-1 flex h-10 flex-row items-center rounded-[1.75rem] px-4 opacity-50 [&:hover]:cursor-default">
                    {item.icon && <div className={cn('w-8 text-purple', isActive && 'text-white')}>{item.icon}</div>}
                    {item.label}
                </span>
            </li>
        );
    }

    return (
        <li {...props}>
            <Link
                to={item.path}
                className={cn(
                    'color-black group mb-1 flex h-10 flex-row items-center rounded-[1.75rem] px-3 lg:px-4 [&:hover]:bg-purple [&:hover]:text-white',
                    isActive && 'active bg-purple text-white'
                )}
            >
                {item.icon && (
                    <div className={cn('w-8 text-purple group-hover:text-white', isActive && 'text-white')}>
                        {item.icon}
                    </div>
                )}
                <span className="hidden lg:block">{item.label}</span>
            </Link>
        </li>
    );
};

export default DashboardMenuItem;
